import { createContext, useEffect, useContext, useCallback, useState } from "react";

import { ethers } from 'ethers'
import { formatUnits, parseUnits } from 'ethers';

import USDCTokenJSON from '../abi/USDCTokenJSON.json'
import AIBCTokenJSON from '../abi/AIBCTokenJSON.json'
import AIBCStakingJSON from '../abi/AIBCStakingJSON.json'
import AIBCDAOJSON from '../abi/AIBCDAOJSON.json'
import UniswapV2PairJSON from '../abi/UniswapV2Pair.json'
import AIBCStakingRewardsJSON from '../abi/AIBCStakingRewardsJSON.json'
import { 
    USDC_ADDRESS,
    TEST_USDC_ADDRESS, 
    AIBC_ADDRESS, 
    AIBC_DONATION_ADDRESS, 
    AIBC_STAKING_ADDRESS, 
    AIBC_DAO_ADDRESS, 
    AIBC_USDC_PAIR_ADDRESS,
    
    AIBC_STAKING_REWARDS_30_ADDRESS,
    AIBC_STAKING_REWARDS_60_ADDRESS,
    AIBC_STAKING_REWARDS_90_ADDRESS,
    AIBC_STAKING_REWARDS_180_ADDRESS,
    POLYGON_RPC_URL,
    
    STAKING_STATUS
} from '../constants'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { fetchFromPinata } from '../api/pinata'

export const statisticsContext = createContext({});

export const StatisticsProvider = ({ children }) => {
    const [balancesData, setBalancesData] = useState({
        aibcBalance: 0,
        aibcAllowance: 0,
        usdcBalance: 0,
        usdcAllowance: 0,
        stakedBalance: 0,
        stakedAllowance: 0,
        stakingRewards30Allowance: 0,
        stakingRewards60Allowance: 0,
        stakingRewards90Allowance: 0,
        stakingRewards180Allowance: 0
    })

    const [proposalList, setProposalList] = useState([]);
    const [proposalCount, setProposalCount] = useState(0);

    const [staking30Rounds, setStaking30Rounds] = useState([]);
    const [staking30CurrentRound, setStaking30CurrentRound] = useState(0);
    const [staking30StakingInfo, setStaking30StakingInfo] = useState([]);

    const [staking60Rounds, setStaking60Rounds] = useState([]);
    const [staking60CurrentRound, setStaking60CurrentRound] = useState(0);
    const [staking60StakingInfo, setStaking60StakingInfo] = useState([]);

    const [staking90Rounds, setStaking90Rounds] = useState([]);
    const [staking90CurrentRound, setStaking90CurrentRound] = useState(0);
    const [staking90StakingInfo, setStaking90StakingInfo] = useState([]);

    const [staking180Rounds, setStaking180Rounds] = useState([]);
    const [staking180CurrentRound, setStaking180CurrentRound] = useState(0);
    const [staking180StakingInfo, setStaking180StakingInfo] = useState([]);

    const [stakingStatistics, setStakingStatistics] = useState({
        totalStaked: 0,
        totalRewards: 0,
        totalUsers: 0,
        totalRounds: 0
    });

    const [tokenPrice, setTokenPrice] = useState(0);

    const { address, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()

    const fetchProposalData = useCallback(async () => {
        if (!isConnected) return;

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()

            const daoContract = new ethers.Contract(AIBC_DAO_ADDRESS, AIBCDAOJSON, signer);

            const count = await daoContract.proposalCount();
            setProposalCount(count);

            let funcList = [];
            for (let index = 1; index <= count; index ++) {
                funcList.push(daoContract.getProposalData(index));
            }

            const resultList = await Promise.all(funcList);
            funcList = [];
            for (let index = 1; index <= count; index ++) {
                funcList.push(daoContract.getDidVote(index));
            }

            const didVoteList = await Promise.all(funcList);

            const proposals = [];
            for (let index = 0; index < count; index ++) {
                const proposal = {};
                const cid = resultList[index][0];
                const data = await fetchFromPinata(cid);
                if (!data) 
                    continue;
                proposal.id = index;
                proposal.cid = cid;
                proposal.data = data;
                proposal.voteFor = resultList[index][1];
                proposal.voteAgainst = resultList[index][2];
                proposal.startBlock = resultList[index][3];
                proposal.endBlock = resultList[index][4];
                proposal.status = resultList[index][5];
                proposal.didVote = didVoteList[index];
                proposals.push(proposal);
            }

            setProposalList(proposals);
        }
        catch (err) {
            console.log(err);
        }
    }, [isConnected, address, walletProvider, proposalList, proposalCount])

    const fetchBalancesData = useCallback(async () => {
        if (!isConnected) return;
        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()

            const aibcContract = new ethers.Contract(AIBC_ADDRESS, AIBCTokenJSON, signer);
            const usdcContract = new ethers.Contract(USDC_ADDRESS, USDCTokenJSON, signer);
            const stakingContract = new ethers.Contract(AIBC_STAKING_ADDRESS, AIBCStakingJSON, signer);

            const [newAibcBalance, newAibcAllowance, newUsdcBalance, newUsdcAllowance, newStakedBalance, newStakedAllowance, 
                newStakingRewards30Allowance, newStakingRewards60Allowance, newStakingRewards90Allowance, newStakingRewards180Allowance] = await Promise.all([
                aibcContract.balanceOf(address),
                aibcContract.allowance(address, AIBC_DONATION_ADDRESS),
                usdcContract.balanceOf(address),
                usdcContract.allowance(address, AIBC_DONATION_ADDRESS),
                stakingContract.getStakedBalance(address),
                aibcContract.allowance(address, AIBC_STAKING_ADDRESS),
                usdcContract.allowance(address, AIBC_STAKING_REWARDS_30_ADDRESS),
                usdcContract.allowance(address, AIBC_STAKING_REWARDS_60_ADDRESS),
                usdcContract.allowance(address, AIBC_STAKING_REWARDS_90_ADDRESS),
                usdcContract.allowance(address, AIBC_STAKING_REWARDS_180_ADDRESS)
            ]);

            // Compare with current state to avoid unnecessary updates
            if (newAibcBalance.toString() !== balancesData.aibcBalance.toString() ||
                newAibcAllowance.toString() !== balancesData.aibcAllowance.toString() ||
                newUsdcBalance.toString() !== balancesData.usdcBalance.toString() ||
                newUsdcAllowance.toString() !== balancesData.usdcAllowance.toString() ||
                newStakedBalance.toString() !== balancesData.stakedBalance.toString() ||
                newStakedAllowance.toString() !== balancesData.stakedAllowance.toString() ||
                newStakingRewards30Allowance.toString() !== balancesData.stakingRewards30Allowance.toString() ||
                newStakingRewards60Allowance.toString() !== balancesData.stakingRewards60Allowance.toString() ||
                newStakingRewards90Allowance.toString() !== balancesData.stakingRewards90Allowance.toString() ||
                newStakingRewards180Allowance.toString() !== balancesData.stakingRewards180Allowance.toString()
            ) {
                setBalancesData({
                    aibcBalance: newAibcBalance,
                    aibcAllowance: newAibcAllowance,
                    usdcBalance: newUsdcBalance,
                    usdcAllowance: newUsdcAllowance,
                    stakedBalance: newStakedBalance,
                    stakedAllowance: newStakedAllowance,
                    stakingRewards30Allowance: newStakingRewards30Allowance,
                    stakingRewards60Allowance: newStakingRewards60Allowance,
                    stakingRewards90Allowance: newStakingRewards90Allowance,
                    stakingRewards180Allowance: newStakingRewards180Allowance
                });
            }
        }
        catch (err) {
            console.log(err)
        }
    }, [isConnected, address, walletProvider, balancesData]);

    const fetchTokenPrice = useCallback(async () => {
        try {
            const ethersProvider = new ethers.JsonRpcProvider(POLYGON_RPC_URL);
            const uniswapV2PairContract = new ethers.Contract(AIBC_USDC_PAIR_ADDRESS, UniswapV2PairJSON, ethersProvider);

            const reserves = await uniswapV2PairContract.getReserves();
            const usdcAmount = Number(formatUnits(reserves[0], 6));
            const aibcAmount = Number(formatUnits(reserves[1], 18));

            const price = (usdcAmount / aibcAmount).toFixed(2);

            setTokenPrice(price);
        }
        catch (err) {
            console.log(err)
        }
    }, [tokenPrice])

    const fetchRoundsInfo = useCallback(async () => {
        try {
            const ethersProvider = new ethers.JsonRpcProvider(POLYGON_RPC_URL);
            const staking30Contract = new ethers.Contract(AIBC_STAKING_REWARDS_30_ADDRESS, AIBCStakingRewardsJSON, ethersProvider);
            const staking60Contract = new ethers.Contract(AIBC_STAKING_REWARDS_60_ADDRESS, AIBCStakingRewardsJSON, ethersProvider);
            const staking90Contract = new ethers.Contract(AIBC_STAKING_REWARDS_90_ADDRESS, AIBCStakingRewardsJSON, ethersProvider);
            const staking180Contract = new ethers.Contract(AIBC_STAKING_REWARDS_180_ADDRESS, AIBCStakingRewardsJSON, ethersProvider);

            let totalStaked = ethers.toBigInt(0);
            let totalUsers = ethers.toBigInt(0);
            let totalRounds = ethers.toBigInt(0);
            let totalRewards = ethers.toBigInt(0);

            const [newStaking30CurrentRound, newStaking60CurrentRound, newStaking90CurrentRound, newStaking180CurrentRound] = await Promise.all([
                staking30Contract.roundIndex(),
                staking60Contract.roundIndex(),
                staking90Contract.roundIndex(),
                staking180Contract.roundIndex()
            ]);

            // if (newStaking30CurrentRound.toString() !== staking30CurrentRound.toString()) {
                let funcList = [];
                for (let index = 1; index <= newStaking30CurrentRound; index ++) {
                    funcList.push(staking30Contract.rounds(index));
                }
                const staking30RoundsList = await Promise.all(funcList);
                let rounds = [];
                for (let i = 0; i < newStaking30CurrentRound; i ++) {
                    const round = {};
                    round.startTime = staking30RoundsList[i].startTime;
                    round.lockTime = staking30RoundsList[i].lockTime;
                    round.stakedAmount = staking30RoundsList[i].stakedAmount;
                    round.returnedAmount = staking30RoundsList[i].returnedAmount;
                    round.orderId = staking30RoundsList[i].orderId;
                    round.invoiceId = staking30RoundsList[i].invoiceId;
                    round.skuNumber = staking30RoundsList[i].skuNumber;
                    round.totalUsers = staking30RoundsList[i].totalUsers;
                    round.status = Number(staking30RoundsList[i].status);
                    // console.log(round.stakedAmount);
                    if (round.status !== STAKING_STATUS.RETURNED) {
                        totalStaked += round.stakedAmount;
                    }
                    totalUsers += round.totalUsers;
                    if (round.status === STAKING_STATUS.RETURNED) {
                        totalRewards += (round.returnedAmount - round.stakedAmount);
                    }
                    rounds.push(round);
                }
                setStaking30CurrentRound(Number(newStaking30CurrentRound));
                setStaking30Rounds(rounds);
            // }
            // if (newStaking60CurrentRound.toString() !== staking60CurrentRound.toString()) {
                funcList = [];
                for (let index = 1; index <= newStaking60CurrentRound; index ++) {
                    funcList.push(staking60Contract.rounds(index));
                }
                const staking60RoundsList = await Promise.all(funcList);
                rounds = [];
                for (let i = 0; i < newStaking60CurrentRound; i ++) {
                    const round = {};
                    round.startTime = staking60RoundsList[i].startTime;
                    round.lockTime = staking60RoundsList[i].lockTime;
                    round.stakedAmount = staking60RoundsList[i].stakedAmount;
                    round.returnedAmount = staking60RoundsList[i].returnedAmount;
                    round.orderId = staking60RoundsList[i].orderId;
                    round.invoiceId = staking60RoundsList[i].invoiceId;
                    round.skuNumber = staking60RoundsList[i].skuNumber;
                    round.totalUsers = staking60RoundsList[i].totalUsers;
                    round.status = Number(staking60RoundsList[i].status);

                    if (round.status !== STAKING_STATUS.RETURNED) {
                        totalStaked += round.stakedAmount;
                    }
                    totalUsers += round.totalUsers;
                    if (round.status === STAKING_STATUS.RETURNED) {
                        totalRewards += (round.returnedAmount - round.stakedAmount);
                    }

                    rounds.push(round);
                }
                setStaking60CurrentRound(Number(newStaking60CurrentRound));
                setStaking60Rounds(rounds);
            // }
            // if (newStaking90CurrentRound.toString() !== staking90CurrentRound.toString()) {
                funcList = [];
                for (let index = 1; index <= newStaking90CurrentRound; index ++) {
                    funcList.push(staking90Contract.rounds(index));
                }
                const staking90RoundsList = await Promise.all(funcList);
                rounds = [];
                for (let i = 0; i < newStaking90CurrentRound; i ++) {
                    const round = {};
                    round.startTime = staking90RoundsList[i].startTime;
                    round.lockTime = staking90RoundsList[i].lockTime;
                    round.stakedAmount = staking90RoundsList[i].stakedAmount;
                    round.returnedAmount = staking90RoundsList[i].returnedAmount;
                    round.orderId = staking90RoundsList[i].orderId;
                    round.invoiceId = staking90RoundsList[i].invoiceId;
                    round.skuNumber = staking90RoundsList[i].skuNumber;
                    round.totalUsers = staking90RoundsList[i].totalUsers;
                    round.status = Number(staking90RoundsList[i].status);

                    if (round.status !== STAKING_STATUS.RETURNED) {
                        totalStaked += round.stakedAmount;
                    }
                    totalUsers += round.totalUsers;
                    if (round.status === STAKING_STATUS.RETURNED) {
                        totalRewards += (round.returnedAmount - round.stakedAmount);
                    }
                    rounds.push(round);
                }
                setStaking90CurrentRound(Number(newStaking90CurrentRound));
                setStaking90Rounds(rounds);
            // }
            // if (newStaking180CurrentRound.toString() !== staking180CurrentRound.toString()) {
                funcList = [];
                for (let index = 1; index <= newStaking180CurrentRound; index ++) {
                    funcList.push(staking180Contract.rounds(index));
                }
                const staking180RoundsList = await Promise.all(funcList);
                rounds = [];
                for (let i = 0; i < newStaking180CurrentRound; i ++) {
                    const round = {};
                    round.startTime = staking180RoundsList[i].startTime;
                    round.lockTime = staking180RoundsList[i].lockTime;
                    round.stakedAmount = staking180RoundsList[i].stakedAmount;
                    round.returnedAmount = staking180RoundsList[i].returnedAmount;
                    round.orderId = staking180RoundsList[i].orderId;
                    round.invoiceId = staking180RoundsList[i].invoiceId;
                    round.skuNumber = staking180RoundsList[i].skuNumber;
                    round.totalUsers = staking180RoundsList[i].totalUsers;
                    round.status = Number(staking180RoundsList[i].status);

                    if (round.status !== STAKING_STATUS.RETURNED) {
                        totalStaked += round.stakedAmount;
                    }
                    totalUsers += round.totalUsers;
                    if (round.status === STAKING_STATUS.RETURNED) {
                        totalRewards += (round.returnedAmount - round.stakedAmount);
                    }
                    rounds.push(round);
                }
                setStaking180CurrentRound(Number(newStaking180CurrentRound));
                setStaking180Rounds(rounds);
            // }

            totalRounds = newStaking30CurrentRound + newStaking60CurrentRound + newStaking90CurrentRound + newStaking180CurrentRound;

            setStakingStatistics({
                totalStaked: totalStaked,
                totalRewards: totalRewards,
                totalRounds: totalRounds,
                totalUsers: totalUsers
            })
        }
        catch (err) {
            console.log(err)
        }
    }, [staking30Rounds, staking60Rounds, staking90Rounds, staking180Rounds, 
        staking30CurrentRound, staking60CurrentRound, staking90CurrentRound, staking180CurrentRound, stakingStatistics])

    const fetchStakedInfo = useCallback(async () => {
        if (!isConnected) return;

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()

            const staking30Contract = new ethers.Contract(AIBC_STAKING_REWARDS_30_ADDRESS, AIBCStakingRewardsJSON, signer);
            const staking60Contract = new ethers.Contract(AIBC_STAKING_REWARDS_60_ADDRESS, AIBCStakingRewardsJSON, signer);
            const staking90Contract = new ethers.Contract(AIBC_STAKING_REWARDS_90_ADDRESS, AIBCStakingRewardsJSON, signer);
            const staking180Contract = new ethers.Contract(AIBC_STAKING_REWARDS_180_ADDRESS, AIBCStakingRewardsJSON, signer);

            const [newStaking30CurrentRound, newStaking60CurrentRound, newStaking90CurrentRound, newStaking180CurrentRound] = await Promise.all([
                staking30Contract.roundIndex(),
                staking60Contract.roundIndex(),
                staking90Contract.roundIndex(),
                staking180Contract.roundIndex()
            ]);

            // if (newStaking30CurrentRound.toString() !== staking30CurrentRound.toString()) {
                let funcList = [];
                for (let index = 1; index <= newStaking30CurrentRound; index ++) {
                    funcList.push(staking30Contract.stakers(address, index));
                }
                const staking30StakingInfoList = await Promise.all(funcList);
                let stakingInfoList = [];
                for (let i = 0; i < newStaking30CurrentRound; i ++) {
                    const stakingInfo = {};
                    stakingInfo.stakedAmount = staking30StakingInfoList[i].stakedAmount;
                    stakingInfo.roundId = staking30StakingInfoList[i].roundId;
                    stakingInfo.withdrawAmount = staking30StakingInfoList[i].withdrawAmount;
                    
                    if (stakingInfo.stakedAmount > 0) {
                        stakingInfoList.push(stakingInfo);
                    }
                }
                setStaking30StakingInfo(stakingInfoList);
            // }
            // if (newStaking60CurrentRound.toString() !== staking60CurrentRound.toString()) {
                funcList = [];
                for (let index = 1; index <= newStaking60CurrentRound; index ++) {
                    funcList.push(staking60Contract.stakers(address, index));
                }
                const staking60StakingInfoList = await Promise.all(funcList);
                stakingInfoList = [];
                for (let i = 0; i < newStaking60CurrentRound; i ++) {
                    const stakingInfo = {};
                    stakingInfo.stakedAmount = staking60StakingInfoList[i].stakedAmount;
                    stakingInfo.roundId = staking60StakingInfoList[i].roundId;
                    stakingInfo.withdrawAmount = staking60StakingInfoList[i].withdrawAmount;

                    if (stakingInfo.stakedAmount > 0) {
                        stakingInfoList.push(stakingInfo);
                    }
                }
                setStaking60StakingInfo(stakingInfoList);
            // }
            // if (newStaking90CurrentRound.toString() !== staking90CurrentRound.toString()) {
                funcList = [];
                for (let index = 1; index <= newStaking90CurrentRound; index ++) {
                    funcList.push(staking90Contract.stakers(address, index));
                }
                const staking90StakingInfoList = await Promise.all(funcList);
                stakingInfoList = [];
                for (let i = 0; i < newStaking90CurrentRound; i ++) {
                    const stakingInfo = {};
                    stakingInfo.stakedAmount = staking90StakingInfoList[i].stakedAmount;
                    stakingInfo.roundId = staking90StakingInfoList[i].roundId;
                    stakingInfo.withdrawAmount = staking90StakingInfoList[i].withdrawAmount;
                    
                    if (stakingInfo.stakedAmount > 0) {
                        stakingInfoList.push(stakingInfo);
                    }
                }
                setStaking90StakingInfo(stakingInfoList);
            // }
            // if (newStaking180CurrentRound.toString() !== staking180CurrentRound.toString()) {
                funcList = [];
                for (let index = 1; index <= newStaking180CurrentRound; index ++) {
                    funcList.push(staking180Contract.stakers(address, index));
                }
                const staking180StakingInfoList = await Promise.all(funcList);
                stakingInfoList = [];
                for (let i = 0; i < newStaking180CurrentRound; i ++) {
                    const stakingInfo = {};
                    stakingInfo.stakedAmount = staking180StakingInfoList[i].stakedAmount;
                    stakingInfo.roundId = staking180StakingInfoList[i].roundId;
                    stakingInfo.withdrawAmount = staking180StakingInfoList[i].withdrawAmount;
                    
                    if (stakingInfo.stakedAmount > 0) {
                        stakingInfoList.push(stakingInfo);
                    }
                }
                setStaking180StakingInfo(stakingInfoList);
            // }
        }
        catch (err) {
            console.log(err)
        }
    }, [staking30StakingInfo, staking60StakingInfo, staking90StakingInfo, staking180StakingInfo, isConnected, walletProvider, address])

    useEffect(() => {
        if (!isConnected) return;

        const intervalId = setInterval(fetchBalancesData, 2000);

        return () => clearInterval(intervalId);
    }, [fetchBalancesData, isConnected]);

    useEffect(() => {
        if (!isConnected) return;

        const intervalId = setInterval(fetchProposalData, 2000);

        return () => clearInterval(intervalId);
    }, [fetchProposalData, isConnected]);

    useEffect(() => {
        const intervalId = setInterval(fetchTokenPrice, 2000);

        return () => clearInterval(intervalId);
    }, [fetchTokenPrice]);

    useEffect(() => {
        const intervalId = setInterval(fetchRoundsInfo, 4000);

        return () => clearInterval(intervalId);
    }, [fetchRoundsInfo]);

    useEffect(() => {
        const intervalId = setInterval(fetchStakedInfo, 4000);

        return () => clearInterval(intervalId);
    }, [fetchStakedInfo]);

    return <statisticsContext.Provider value={{ 
        balancesData, proposalCount, proposalList, tokenPrice, 
        staking30Rounds, staking60Rounds, staking90Rounds, staking180Rounds,
        staking30CurrentRound, staking60CurrentRound, staking90CurrentRound, staking180CurrentRound,
        staking30StakingInfo, staking60StakingInfo, staking90StakingInfo, staking180StakingInfo,
        stakingStatistics }}>{children}</statisticsContext.Provider>
}

export const useStatisticsData = () => {
    const context = useContext(statisticsContext);

    if (context === undefined) {
        throw new Error("useStatisticsData should be defined.");
    }

    return context;
}