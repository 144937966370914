import axios from 'axios'
import { PINATA_API_KEY, PINATA_API_SECRET_KEY } from '../constants'

export const uploadToPinata = async (proposalData) => {
    try {
        const jsonData = JSON.stringify(proposalData);

        const response = await axios.post(
            'https://api.pinata.cloud/pinning/pinJSONToIPFS',
            jsonData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    pinata_api_key: PINATA_API_KEY,
                    pinata_secret_api_key: PINATA_API_SECRET_KEY
                }
            }
        );

        const cid = response.data.IpfsHash;
        console.log(`Proposal uploaded to IPFS with CID: ${cid}`);
        return cid;
    }
    catch (err) {
        console.log('Error uploading to Pinata:', err);
    }
    return '';
}

export const fetchFromPinata = async (cid) => {
    try {
        const response = await axios.get(`https://gateway.pinata.cloud/ipfs/${cid}`);
        const data = response.data;
        return data;
    }
    catch (err) {
        console.log(err);
    }
    return null;
}