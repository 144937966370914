import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';

const SelectProposals = () => {

    const navigate = useNavigate();

    const handleBack = (type) => {
        navigate(-1);
    }
    return (
        <div className='select-proposal'>
            <div className="proposal-container d-flex flex-column">
                <div className="proposal-header">
                    <button className="back-button mb-3" onClick={handleBack}><FaArrowLeft /></button>
                    <p className='mb-3'>
                    Submit a proposal for the AIBC DAO community to vote on. Once submitted, your proposal will appear in the proposals feed.
                    </p>
                </div>
                <div className="proposal-types">
                    <h2 className='mb-4'>Choose your proposal type:</h2>
                    <div className="proposal-options">
                        <NavLink to='/proposals/create/governance' className='text-decoration-none text-dark proposal-card governance'>
                            <h3>Governance Proposal</h3>
                            <p>Changes to the DAO’s governing policies and procedures that shape the direction and decision-making process of the organization.</p>
                        </NavLink>
                        <NavLink to='/proposals/create/grantmaking' className='text-decoration-none text-dark proposal-card grantmaking'>
                            <h3>Grantmaking Proposal</h3>
                            <p>Formal process for approving launch of AIBC DAO Grants round and setting its budgetary parameters.</p>
                        </NavLink>
                        <NavLink to='/proposals/create/membership' className='text-decoration-none text-dark proposal-card membership'>
                            <h3>Membership Proposal</h3>
                            <p>Admission and removal of members to the DAO’s executive committee, as well as the greater DAO community.</p>
                        </NavLink>
                        <NavLink to='/proposals/create/krizzar' className='text-decoration-none text-dark proposal-card krizzar'>
                            <h3>Community Proposal</h3>
                            <p>Discuss new ideas and suggestions in Shuffle, a decentralized social media platform.</p>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectProposals;
