import React from 'react';
import { NavLink } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className="hero-section">
    {/* <img src={heroimg} className='img-fluid' alt='background-img'/> */}
      <div className="video-background">
        <video autoPlay loop muted playsInline>
          <source src="/images/landing.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div className='hero-content'>

          <h3>AIBC Foundation</h3>
          <h1>Advancing AI, Blockchain & Cybersecurity</h1>
          <p>Join us in shaping a future where Artificial Intelligence, Blockchain and Cybersecurity technologies serve humanity with integrity and purpose.</p>
          <div className="hero-buttons d-flex flex-column" style={{width: 'fit-content'}}>
            <div className=' d-flex'>
              <NavLink to="/our-philosophy" className="btn btn-first">Our Philosophy</NavLink>
              <NavLink to="/donate" className="btn btn-first">Donate Now</NavLink>
            </div>
            <NavLink to="/whitepaper" className="btn btn-secondary">Whitepaper</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
