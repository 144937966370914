import React from 'react';
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaTiktok, FaLinkedinIn, FaPinterestP, FaGlobe } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="footer-logo" style={{ textAlign: 'center'}}>
            <img src="/images/logo.png" alt="logo" />
          </div>
          <p>Connect with us, explore opportunities, and contribute to a mission that prioritizes humanity in the age of technology.</p>
        </div>
        <div className="footer-column col-lg-3 col-md-4 col-sm-6 col-12">
          <h4>Quick Link</h4>
          <div className='d-flex flex-column gap-1'>
            <NavLink to='/'>Home</NavLink>
            <NavLink to='/proposals'>Proposals</NavLink>
            <NavLink to='/our-philosophy'>Our Philosophy</NavLink>
            <NavLink to='/donate'>Donate</NavLink>
          </div>
        </div>
        <div className="footer-column col-lg-3 col-md-4 col-sm-6 col-12">
          <h4>Explore:</h4> 
          <div className='d-flex flex-column gap-1'>
          <NavLink to="/ecosystem">Ecosystem</NavLink>
          <NavLink to="/tokenomics">Tokenomics</NavLink>
          <NavLink to="/airdrop">Airdrop</NavLink>
          <NavLink to="/whitepaper">WhitePaper</NavLink>
        </div>
        </div>
        <div className="footer-column col-lg-3 col-md-4 col-sm-12 col-12">
          <h4>Stay Tuned With Us</h4>
          <form className="subscribe-form">
            <input type="email" placeholder="Email Address" />
            <button type="submit">Subscribe Now</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-links">
          <div className="footer-bottom-left d-flex">
            <span className='d-flex align-items-center'><FaGlobe /> USA</span>
            <span>Terms of Service</span>
            <span>Privacy Policy</span>
            <span>Sitemap</span>
            <span>Privacy Choices</span>
          </div>
          <div className="footer-bottom-right d-flex">
            <FaFacebookF />
            <FaTwitter />
            <FaYoutube />
            <FaInstagram />
            <FaTiktok />
            <FaLinkedinIn />
            <FaPinterestP />
          </div>
        </div>
      </div>
      <p className="copyright-text text-center mt-5 mb-0">Powered by Iridium Technologies</p>
    </footer>
  );
};

export default Footer;


      