import React from "react";
import { NavLink } from "react-router-dom";
const HowItWorks = () => {
    return (
        <section className="how-it-works-section">
            <div className="how-it-works-header">
                <span className="badge">HOW IT WORKS</span>
                <h2 className="how-it-works-title">
                    Start Making A Profit With Crypto Staking Right Now!
                </h2>
            </div>
            <div className="how-it-works-box mb-5">
                <div className="steps-container-vertical">
                    {/* Step 1 */}
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                Our staking pools are set along 4 predetermined durations.
                                <br />30 days, 60 days, 90 days and 180 days.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    {/* Step 2 */}
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>All liquidity locked into the smart contract is utilized for the purchase of inventory. </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    {/* Step 3 */}
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                Our contracts only accept USDC in order to participate with the platform.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                Our contracts only accept USDC in order Once a minimum amount has been contributed into the contract, the batched contract can then be initiated. At this juncture, withdrawal will not be possible by the participants until the maturity.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                The trigger event which will activate the contract is the issuance of an order id. This is then followed by the issuance of the SKU number and finally, the issuance of an invoice id.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                The order id is the tracking number of the purchase order that is accompanied to this identifier. This purchase order number tracks a SKU number, which is a unique identifier issued to the inventory. Finally, the invoice number is the number issued by the supplier to the purchaser demanding payment.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                Payment is remitted to the supplier and inventory is released. Simultaneously, the batched contract receives a wrapped USDC as a token within the liquidity pool and an inventory token which collateralizes the inventory within the staking pool. This wrapped USDC is a token that represents the stake within the pool and will later act as a redemption token in order to collect the participant’s reward.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                The inventory purchased undergoes a sales cycle that can be repeated several times within the predetermined maturity dates. This is what allows for compounding of returns to occur over a longer period of time leading to the higher rewards.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                                Inventory items purchased include everyday items that people consume globally. As a result, product turnover is very quick, often ranging only 3 to 5 days.
                            </p>
                        </div>

                    </div>
                </div>
                <NavLink to='/stake' className="start-staking-button text-decoration-none">Start staking</NavLink>
            </div>
        </section>
    );
};

export default HowItWorks;
