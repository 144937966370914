import React from 'react';
import { NavLink } from 'react-router-dom';

const MembershipResources = () => {
    return (
        <>
            
            <div className="membership-resources text-dark py-0">
                <div className="content">
                    <h1>Our Solution</h1>
                    <h2>An Integrated Decentralized Ecosystem </h2>
                    <p className=' text-start'>
                        The AIBC Foundation proposes a unified ecosystem that integrates social media, ecommerce, supply chains, and global payment systems on a decentralized ledger.equitably.<br/>
                        <b>This solution: </b>
                        <div className=' text-start'>
                            <li>Promotes transparency, leading to disintermediation and cost savings.</li>
                            <li>Harnesses AI for predictive analytics and unparalleled efficiency.</li>
                            <li>Utilizes blockchain to democratize trust and decentralization.</li>
                            <li>Enhances security through blockchain andcybersecurity technologies.</li>
                        </div>
                        Such an ecosystem will generate significant economic value by streamlining processes and reducing costs.
                    </p>
                </div>
            </div>
            <div className="membership-resources  text-dark pt-0">
                <div className="content">
                    <h1>Membership</h1>
                    <h2>Join the AIBC Movement</h2>
                    <p className='text-start'>
                        When you donate to AIBC Foundation, you become an integral part of a global network committed to transformative change. As a member, you’ll enjoy exclusive benefits, including:
                        <div className=' text-start'>
                        <li>Access to AIBC-exclusive events promoting thought leadership.</li>
                        <li>Discounts on products from Krizaar.com.</li>
                        <li>NFT rewards, staking opportunities, and free token swap services.</li>
                        </div>
                        Join us and be a catalyst for ethical innovation!

                    </p>
                </div>
            </div>
        </>
    );
};

export default MembershipResources;
