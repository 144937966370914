import React, { useState } from 'react';
import { FaArrowLeft, FaEdit, FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { uploadToPinata } from '../../api/pinata';
import { useStatisticsData } from '../../contexts/statisticsContext';
import { useActions } from '../../hooks/useActions';
import { useBlockNumber } from '../../hooks/useBlockNumber';
import { formatUnits, parseUnits } from 'ethers';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';

const CreateGovernanceProposal = () => {
    const [proposalType, setProposalType] = useState('Governance');
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [specification, setSpecification] = useState('');
    const [motivation, setMotivation] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [amount, setAmount] = useState(0);
    const [showPreview, setShowPreview] = useState(false);

    const { balancesData } = useStatisticsData();
    const { getBlockNumber } = useBlockNumber();

    const aibcBalance = Number(formatUnits(balancesData.aibcBalance, 18));
    const stakedBalance = Number(formatUnits(balancesData.stakedBalance, 18));
    const stakedAllowance = Number(formatUnits(balancesData.stakedAllowance, 18));

    const { address, isConnected } = useWeb3ModalAccount();
    const { approveAIBCToStaking, stakeAIBC, createProposal } = useActions();

    const handlePreviewChange = () => {
        setShowPreview(!showPreview)
    }

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }

    const onSubmitProposal = async () => {
        if (!isConnected) return;
        if (stakedBalance < 100) return;

        if (title == '' || summary == '' || specification == '' || motivation == '') return;

        const proposalData = {
            type: proposalType,
            title: title,
            summary: summary,
            specification: specification,
            motivation: motivation,
            conclusion: conclusion,
            startDate: startDate,
            endDate: endDate
        }

        try {

            const proposalCID = await uploadToPinata(proposalData);
            
            const startTimestamp = (new Date(startDate)).getTime() / 1000;
            const endTimeStamp = (new Date(endDate)).getTime() / 1000;
            const { delay, duration } = await getBlockNumber(startTimestamp, endTimeStamp);

            const status = await createProposal(proposalCID, delay, duration);

            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }       
    }

    const onApprove = async () => {
        if (!isConnected) return;

        if (stakedAllowance < amount) {
            const approveStatus = await approveAIBCToStaking(balancesData.aibcBalance);
            if (approveStatus.success) {
                console.log(approveStatus.message);
            } else {
                console.log(approveStatus.message);
                return;
            }
        }
    }

    const onStake = async () => {
        if (!isConnected) return;
        if (stakedAllowance < amount) return;

        try {
            const status = await stakeAIBC(parseUnits(amount.toFixed(2), 18));
            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='select-proposal'>
            <div className="proposal-container">
                <div className="create-proposal-container">
                    <div className="create-proposal-header">
                        <button className="back-button" onClick={handleBack}><FaArrowLeft /></button>
                        <span className="create-proposal-header-text">New Governance Proposal</span>
                    </div>
                    <div className='create-proposal-information'>
                        <p className="governance-badge">Governance</p>
                        <p className='proposal-info mb-4'>
                        This type of proposal allows members of the DAO to propose and vote on changes to the organization's policies and procedures. These proposals can address important matters such as the DAO's decision-making process, strategic direction, governance structure, and other relevant topics. Governance proposals enable the community to have a say in how the DAO is run and ensure that it continues to operate in a fair and transparent manner.
                        </p>
                    </div>
                    <div className="create-proposal-type">
                        <label>Proposal type</label>
                        <select value={proposalType} onChange={(e) => setProposalType(e.target.value)}>
                            <option value="Governance">Governance</option>
                        </select>
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter the question you would like to ask the community"
                        />
                    </div>
                    {!showPreview ?
                        <>
                            <div className="create-proposal-input-group">
                                <label>Summary</label>
                                <textarea
                                    value={summary}
                                    onChange={(e) => setSummary(e.target.value)}
                                    placeholder="Summarize your proposal in a few sentences"
                                    rows={5}
                                />
                            </div>
                            <div className="create-proposal-input-group">
                                <label>Specification</label>
                                <textarea
                                    value={specification}
                                    onChange={(e) => setSpecification(e.target.value)}
                                    placeholder="Describe your proposal in detail, including all the specifics you would like voters to consider"
                                    rows={5}
                                />
                            </div>
                            <div className="create-proposal-input-group">
                                <label>Motivation/Impact</label>
                                <textarea
                                    value={motivation}
                                    onChange={(e) => setMotivation(e.target.value)}
                                    placeholder="Why is this proposal necessary and/or important? If successful, what impact will this proposal have on the DAO?"
                                    rows={5}
                                />
                            </div>
                            <div className="create-proposal-input-group">
                                <label>Conclusion <span>(optional)</span></label>
                                <textarea
                                    value={conclusion}
                                    onChange={(e) => setConclusion(e.target.value)}
                                    placeholder="What final information would you like to share about your proposal?"
                                    rows={5}
                                />
                            </div>
                        </> : <></>}
                    <div className='preview-and-edit-button'>
                        {showPreview ? <button className='preview-button' onClick={handlePreviewChange}>Edit <FaEdit /></button> :
                            <button className='preview-button' onClick={handlePreviewChange}>Preview <FaEye /></button>
                        }
                    </div>
                    <div className="create-proposal-voting-period mb-5">
                        <label>Voting period</label>
                        <div className='create-proposal-period-selection'>
                            <div className='selection-item'>
                                <label>Start</label>
                                <input
                                    type="datetime-local"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className='selection-item'>
                                <label>End</label>
                                <input
                                    type="datetime-local"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="create-proposal-voting-period mb-5">
                        <label>Staking Information</label>
                        <div className='create-proposal-period-selection'>
                            <div className='selection-item'>
                                <label>Your Staked Amount: {stakedBalance} AIBC, Your AIBC Balance: {aibcBalance} AIBC</label>
                                <div className='create-proposal-stake'>
                                    <input
                                        type="text"
                                        className='create-proposal-stake-input'
                                        value={amount}
                                        onChange={(e) => setAmount(parseInt(e.target.value == ""? '0': e.target.value))}
                                    />
                                    {amount > stakedAllowance && <button className='create-proposal-submit-button approve' onClick={onApprove}>Approve</button>}
                                    <button className='create-proposal-submit-button' onClick={onStake}>Stake</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="create-proposal-submit-button" onClick={onSubmitProposal}>Submit Proposal</button>
                </div>
            </div>
        </div>
    );
};

export default CreateGovernanceProposal;
