import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';


const CoinsSlider = () => {

  const cryptoCoinsLogos = [
    { name: "Bitcoin", logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png" },
    { name: "Ethereum", logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },
    { name: "Binance Coin", logo: "https://cryptologos.cc/logos/binance-coin-bnb-logo.png" },
    { name: "Cardano", logo: "https://cryptologos.cc/logos/cardano-ada-logo.png" },
    { name: "Ripple (XRP)", logo: "https://cryptologos.cc/logos/xrp-xrp-logo.png" },
    { name: "Dogecoin", logo: "https://cryptologos.cc/logos/dogecoin-doge-logo.png" },
    { name: "Solana", logo: "https://cryptologos.cc/logos/solana-sol-logo.png" },
    { name: "Polygon (MATIC)", logo: "https://cryptologos.cc/logos/polygon-matic-logo.png" },
    { name: "Litecoin", logo: "https://cryptologos.cc/logos/litecoin-ltc-logo.png" },
    { name: "Shiba Inu", logo: "https://cryptologos.cc/logos/shiba-inu-shib-logo.png" },
    { name: "Avalanche", logo: "https://cryptologos.cc/logos/avalanche-avax-logo.png" },
    { name: "Chainlink", logo: "https://cryptologos.cc/logos/chainlink-link-logo.png" },
    { name: "Stellar", logo: "https://cryptologos.cc/logos/stellar-xlm-logo.png" },
    { name: "Uniswap", logo: "https://cryptologos.cc/logos/uniswap-uni-logo.png" },
    { name: "Tether (USDT)", logo: "https://cryptologos.cc/logos/tether-usdt-logo.png" },
    { name: "USD Coin (USDC)", logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png" },
    { name: "Tron", logo: "https://cryptologos.cc/logos/tron-trx-logo.png" },
    { name: "Monero", logo: "https://cryptologos.cc/logos/monero-xmr-logo.png" },
    { name: "Cosmos", logo: "https://cryptologos.cc/logos/cosmos-atom-logo.png" }
  ];

  return (
    <>
      <div className="how-it-works-section">
        <div className="container">
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            loop={true}
            navigation
            pagination={{ clickable: true }}
            className="pb-5"
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            speed={1200}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {cryptoCoinsLogos.map((logo, index) => (
              <SwiperSlide key={index}>
                <div className=" d-flex justify-content-center" >

                  <img style={{width: '80px'}} src={logo.logo} alt={logo.name} className="partner-logo img-fluid" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default CoinsSlider
