import React from 'react'
import { formatUnits } from 'ethers'

import { transactionHeader } from '../../../data'
import { transactions } from '../../../data'
// import { useTransactionsData } from 'hooks/useTransactions'
import { useStatisticsData } from '../../../contexts/statisticsContext'

import './index.css'
import { getRecentRounds, getStakingStatusText } from '../../../utils/utils'

const Transactions = () => {
    const { staking30CurrentRound, staking60CurrentRound, staking90CurrentRound, staking180CurrentRound, 
        staking30Rounds, staking60Rounds, staking90Rounds, staking180Rounds } = useStatisticsData();

    let recentRounds = [];
    const recentRound30 = getRecentRounds(staking30CurrentRound, staking30Rounds, 30);
    const recentRound60 = getRecentRounds(staking60CurrentRound, staking60Rounds, 60);
    const recentRound90 = getRecentRounds(staking90CurrentRound, staking90Rounds, 90);
    const recentRound180 = getRecentRounds(staking180CurrentRound, staking180Rounds, 180);

    recentRounds = recentRounds.concat(recentRound180).concat(recentRound90).concat(recentRound60).concat(recentRound30);

    return (
        <div className='transactions-container'>
            <div className='card-container'>
                <div className='card-title-wrapper'>
                    <div className='card-title'>
                        Recent Rounds
                    </div>
                </div>
                <div className='transaction-grid-container'>
                    <div className='grid'>
                        { transactionHeader.map(( headerText, index) => (
                            <span className='header-cell' key={index}><strong>{headerText}</strong></span>
                        ))}
                        {
                            recentRounds.reverse().map((rowData, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <span className='cell index' key={`a-${index}`}>{rowData.index}</span>
                                        <span className='cell start-time' key={`b-${index}`}>{rowData.startTime}</span>
                                        <span className='cell lock-time' key={`c-${index}`}>{rowData.lockTime}</span>
                                        <span className='cell lock-period' key={`d-${index}`}>{rowData.lockPeriod}</span>
                                        <span className='cell staked-amount' key={`e-${index}`}>{rowData.stakedAmount}</span>
                                        <span className='cell returned-amount' key={`f-${index}`}>{rowData.returnedAmount}</span>
                                        <span className='cell order-id' key={`g-${index}`}>{rowData.orderId}</span>
                                        <span className='cell invoice-id' key={`h-${index}`}>{rowData.invoiceId}</span>
                                        <span className='cell sku-number' key={`i-${index}`}>{rowData.skuNumber}</span>
                                        <span className='cell status' key={`j-${index}`}>{getStakingStatusText(rowData.status)}</span>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transactions