import { useState, useEffect, useCallback } from 'react'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { ethers } from 'ethers'

import { POLYGON_BLOCK_MINING_SPEED } from '../constants'

export const useBlockNumber = () => {
    const { isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const getBlockNumber = async (startTime, endTime) => {
        if (!isConnected) return { delay: 0, duration: 0 };

        try {
            const provider = new ethers.BrowserProvider(walletProvider);
            const latestBlock = await provider.getBlock('latest');
            const currentTime = latestBlock.timestamp;

            const delay = Math.max(
                0,
                Math.floor((startTime - currentTime) / POLYGON_BLOCK_MINING_SPEED)
            );

            const duration = Math.max(
                0,
                Math.floor((endTime - startTime) / POLYGON_BLOCK_MINING_SPEED)
            );

            return {
                delay, duration
            }
        }
        catch (err) {
            console.log("Error getting block number:", err);
            return { delay: 0, duration: 0 }
        }
    }

    return { getBlockNumber }
}