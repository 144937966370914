import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { formatAddress } from './utils/utils';

const Header = () => {
  const [isProposalPage, setisProposalPage] = useState(false)
  const location = useLocation();

  const { address, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();

  useEffect(() => {
    if (location.pathname.includes('/proposals')) {
      setisProposalPage(true)
    }
  }, [])
  
  const onConnect = () => {
    open();
  }

  const onProfile = () => {
    console.log(address);
  }

  return (
    <nav className={`navbar navbar-expand-lg navbar-light fixed-top custom-navbar ${isProposalPage ? 'proposal-page' : ''}`}>
      <div className="container-fluid">
        <NavLink className="navbar-brand d-flex align-items-center" to="/">
          <img className='img-fluid' src='/images/logo.png' style={{ width: '40px', height: '40px', marginRight: '10px' }} alt='Logo' />
          <h1 className='logo-title'>AIBC</h1>
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3 mx-3">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/proposals">Proposals</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/our-philosophy">Our Philosophy</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/donate">Donate Now</NavLink>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Explore
              </a>
              <ul className="dropdown-menu">
                <li><NavLink className="dropdown-item" to="/swap">Swap</NavLink></li>
                <li><NavLink className="dropdown-item" to="/staking">Stake</NavLink></li>
                <li><NavLink className="dropdown-item" to="/affluence">Affluence</NavLink></li>
                <li><NavLink className="dropdown-item" to="/sellersclub">Sellers Club</NavLink></li>
                <li><NavLink className="dropdown-item" to="/ecosystem">Ecosystem</NavLink></li>
                <li><NavLink className="dropdown-item" to="/tokenomics">Tokenomics</NavLink></li>
                <li><NavLink className="dropdown-item" to="/airdrop">Airdrop</NavLink></li>
                <li><NavLink className="dropdown-item" to="/whitepaper">WhitePaper</NavLink></li>
              </ul>
            </li>
            <li className="nav-item">
              { 
                !isConnected ? <button className="btn connect" onClick={onConnect}>Connect Wallet</button> :
                <button className="btn profile" onClick={onProfile}>{ formatAddress(address) }</button>
              }
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
