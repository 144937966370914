import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { ethers } from 'ethers';
import { AIBC_ADDRESS, USDC_ADDRESS, AIBC_DAO_ADDRESS, AIBC_DONATION_ADDRESS, AIBC_STAKING_ADDRESS, 
    AIBC_STAKING_REWARDS_30_ADDRESS, AIBC_STAKING_REWARDS_60_ADDRESS, AIBC_STAKING_REWARDS_90_ADDRESS, AIBC_STAKING_REWARDS_180_ADDRESS,
    TEST_USDC_ADDRESS
} from '../constants';

import AIBCTokenJSON from '../abi/AIBCTokenJSON.json'
import USDCTokenJSON from '../abi/USDCTokenJSON.json'
import AIBCStakingJSON from '../abi/AIBCStakingJSON.json'
import AIBCDAOJSON from '../abi/AIBCDAOJSON.json'
import AIBCDonationJSON from '../abi/AIBCDonationJSON.json'
import AIBCStakingRewardsJSON from '../abi/AIBCStakingRewardsJSON.json'

export const useActions = () => {
    const { isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const approveAIBCToStaking = async (amount) => {
        if (!isConnected) return;

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            const aibcContract = new ethers.Contract(AIBC_ADDRESS, AIBCTokenJSON, signer);
            const tx = await aibcContract.approve(AIBC_STAKING_ADDRESS, amount);
            await tx.wait();

            return { success: true, message: "AIBC Token Approved Successfully!" };
        }
        catch (err) {
            console.log(err)
            return { success: false, message: err.reason }
        }
    }

    const approveUSDCToDonation = async (amount) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!"};

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            const usdcContract = new ethers.Contract(USDC_ADDRESS, USDCTokenJSON, signer);
            const tx = await usdcContract.approve(AIBC_DONATION_ADDRESS, amount);
            await tx.wait();

            return { success: true, message: "USDC Token Approved Succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const approveUSDCToStakingRewards = async (amount, lockPeriod) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!"};

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            let contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;

            if (lockPeriod == 30) {
                contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;
            }
            else if (lockPeriod == 60) {
                contractAddress = AIBC_STAKING_REWARDS_60_ADDRESS;
            }
            else if (lockPeriod == 90) {
                contractAddress = AIBC_STAKING_REWARDS_90_ADDRESS;
            }
            else if (lockPeriod == 180) {
                contractAddress = AIBC_STAKING_REWARDS_180_ADDRESS;
            }

            const usdcContract = new ethers.Contract(USDC_ADDRESS, USDCTokenJSON, signer);
            const tx = await usdcContract.approve(contractAddress, amount);
            await tx.wait();

            return { success: true, message: "USDC Token Approved Succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const stakeToStakingRewards = async (roundId, amount, lockPeriod) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!"};

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            let contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;

            if (lockPeriod == 30) {
                contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;
            }
            else if (lockPeriod == 60) {
                contractAddress = AIBC_STAKING_REWARDS_60_ADDRESS;
            }
            else if (lockPeriod == 90) {
                contractAddress = AIBC_STAKING_REWARDS_90_ADDRESS;
            }
            else if (lockPeriod == 180) {
                contractAddress = AIBC_STAKING_REWARDS_180_ADDRESS;
            }

            const stakingRewardsContract = new ethers.Contract(contractAddress, AIBCStakingRewardsJSON, signer);

            const tx = await stakingRewardsContract.stake(roundId, amount);
            await tx.wait();

            return { success: true, message: "Staked USDC Succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const unstakeFromStakingRewards = async (roundId, amount, lockPeriod) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!"};

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            let contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;

            if (lockPeriod == 30) {
                contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;
            }
            else if (lockPeriod == 60) {
                contractAddress = AIBC_STAKING_REWARDS_60_ADDRESS;
            }
            else if (lockPeriod == 90) {
                contractAddress = AIBC_STAKING_REWARDS_90_ADDRESS;
            }
            else if (lockPeriod == 180) {
                contractAddress = AIBC_STAKING_REWARDS_180_ADDRESS;
            }

            const stakingRewardsContract = new ethers.Contract(contractAddress, AIBCStakingRewardsJSON, signer);
            const tx = await stakingRewardsContract.unstake(roundId, amount);
            await tx.wait();

            return { success: true, message: "UnStaked USDC Succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const withdrawFromStakingRewards = async (roundId, amount, lockPeriod) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!"};

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            let contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;

            if (lockPeriod == 30) {
                contractAddress = AIBC_STAKING_REWARDS_30_ADDRESS;
            }
            else if (lockPeriod == 60) {
                contractAddress = AIBC_STAKING_REWARDS_60_ADDRESS;
            }
            else if (lockPeriod == 90) {
                contractAddress = AIBC_STAKING_REWARDS_90_ADDRESS;
            }
            else if (lockPeriod == 180) {
                contractAddress = AIBC_STAKING_REWARDS_180_ADDRESS;
            }

            const stakingRewardsContract = new ethers.Contract(contractAddress, AIBCStakingRewardsJSON, signer);
            const tx = await stakingRewardsContract.withdraw(roundId, amount);
            await tx.wait();

            return { success: true, message: "Withdraw USDC Succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const donate = async (amount) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!" };

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            const donationContract = new ethers.Contract(AIBC_DONATION_ADDRESS, AIBCDonationJSON, signer);
            const tx = await donationContract.donate(amount);
            await tx.wait();

            return { success: true, message: "Donate token successfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const stakeAIBC = async (amount) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!" };

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            const stakingContract = new ethers.Contract(AIBC_STAKING_ADDRESS, AIBCStakingJSON, signer);
            const tx = await stakingContract.stake(amount);
            await tx.wait();

            return { success: true, message: "Staking token successfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const withdrawAIBC = async (amount) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!" }

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            const stakingContract = new ethers.Contract(AIBC_STAKING_ADDRESS, AIBCStakingJSON, signer);
            const tx = await stakingContract.withdraw(amount);
            await tx.wait();

            return { success: true, message: "Withdraw token succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason }
        }
    }

    const createProposal = async (proposalCID, delay, duration) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!" }

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            const daoContract = new ethers.Contract(AIBC_DAO_ADDRESS, AIBCDAOJSON, signer);
            const tx = await daoContract.createProposal(proposalCID, delay, duration);
            await tx.wait();

            return { success: true, message: "Create Proposal succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason };
        }
    }

    const voteToProposal = async (proposalIndex, _vote) => {
        if (!isConnected) return { success: false, message: "Wallet is not connected yet!" };

        try {
            const ethersProvider = new ethers.BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner()

            const daoContract = new ethers.Contract(AIBC_DAO_ADDRESS, AIBCDAOJSON, signer);
            console.log(proposalIndex);
            const tx = await daoContract.vote(proposalIndex, _vote);
            await tx.wait();

            return { success: true, message: "Create Proposal succesfully!" };
        }
        catch (err) {
            console.log(err);
            return { success: false, message: err.reason };
        }
    }

    return {
        approveAIBCToStaking,
        approveUSDCToDonation,
        donate,
        stakeAIBC,
        withdrawAIBC,
        createProposal,
        voteToProposal,
        approveUSDCToStakingRewards,
        stakeToStakingRewards,
        unstakeFromStakingRewards,
        withdrawFromStakingRewards
    }
}