import React from "react";
import { NavLink } from "react-router-dom";

const StakingPageHeroSection = () => {
  return (
    <div className="staking-page-hero-section">
      <h1>
        Earn Rewards With <span className="staking-page-gradient-text">Crypto Staking</span> On{" "}
        <span className="staking-page-gradient-text">AIBC Foundation</span>
      </h1>
      <p>
      At AIBC, we believe a decentralized world is a fair and efficient world. Therefore, one way we aim to unlock these efficiencies is via our staking platform, which is dedicated to facilitating and enabling peer to peer microfinance transactions.
      </p>
      <NavLink to='/stake' className="staking-page-cta-button text-decoration-none">Launch Now</NavLink>
      <div className="staking-page-image">
        <img src="/images/staking-hero.png" alt="Crypto Staking Platforms" />
      </div>
    </div>
  );
};

export default StakingPageHeroSection;
