import React from 'react'

const Ecosystem = () => {
    return (
        <>
            <div className='terms-of-service my-5' style={{paddingTop: "5rem"}}>
                <h1 className="my-3">Ecosystem: <br/>AIBC’s Revolutionary Platforms</h1>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className="checkout__step-number">
                        <span>1</span>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Shuffle Hub</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>A blockchain-powered social media platform enabling short-form video creation and live streaming. Integrated ecommerce features allow users to embrace the next generation of social commerce.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className="checkout__step-number">
                        <span>2</span>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Krizaar</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>A blockchain-based multivendor marketplace providing product and supply chain authenticity verification, ensuring transparency and protection against counterfeit goods.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className="checkout__step-number">
                        <span>3</span>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Flobux</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>A blockchain-based freight management system addressing inefficiencies in global logistics. Flobux (mobile) and Digiloads (web) streamline freight tracking, documentationtransferance, and payment remittance for carriers and 3PLs. Beta release slated for mid-2025.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ecosystem
