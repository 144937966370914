import React from 'react'
import AirdropBoxes from './AirdropBoxes'
import AirdropBoxes2 from './AirdropBoxes2'

const Airdrop = () => {
    return (
        <>
            <div className='terms-of-service my-5 pt-5'>
                <h1 className="my-3">Airdrop: <br />Unlock Your Share of Innovation</h1>
                <div className='flex-column' style={{ cursor: 'auto' }} >
                    <p style={{ textAlign: 'justify' }}>Join the Revolution with the AIBC DAO Token Airdrop!</p>
                    <p style={{ textAlign: 'justify' }}>The Artificial Intelligence, Blockchain & Cybersecurity (AIBC) Foundation is thrilled to announce its exclusive Airdrop campaign! This is your chance to become part of a global movement shaping the future of artificial intelligence, blockchain, and cybersecurity technologies.</p>
                </div>
            </div>
            <AirdropBoxes2 />
            {/* <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Why Participate in the AIBC Airdrop?</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>By participating in the AIBC Token Airdrop, you gain:
                                    <ul>
                                        <li><b>Free AIBC Tokens: </b> A hyper-deflationary ERC20 token built on the Polygon blockchain.</li>
                                        <li><b>Governance RIghts: </b> A voice in shaping the future of the AIBC decentralized ecosystem.</li>
                                        <li><b>Exclusive Member Benefits: </b> Enjoy perks like discounts, staking rewards, NFTs, and more.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>What is the AIBC Token?</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>The AIBC Token is the backbone of our decentralized autonomous organization (DAO). It powers our mission to drive innovation in AI, blockchain, and cybersecurity while enabling transparency, trust, and decentralization.<br />
                                    <b>Key Features of the AIBC Token:</b>
                                    <ul>
                                        <li><b>Built on Polygon: </b> Faster and cost-effective transactions.</li>
                                        <li><b>Hyper-Deflationary: </b> Limited supply ensures value growth over time.</li>
                                        <li><b>Utility & Governance: </b> Participate in decision-making, staking, and cross-chain swaps.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            <div className='terms-of-service my-5 pt-5'>

                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>How to Claim Your Free AIBC Tokens</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>Getting started with the AIBC Airdrop is simple:<br />
                                    <ol>
                                        <li><b>Sign Up: </b>Create an account on our Affluence platform or click here.</li>
                                        <li><b>KYC: </b>Complete your KYC on Affluence.</li>
                                        <li>Once registration is complete you will be able to perform tasks in two primary branches.</li>
                                        <li><b>First is Affiliate: </b>This branch allows you to generate links for product and campaign sales. Be sure to generate links to promote the AIBC DAO Token.</li>
                                        <li><b>Second is Airdrops: </b>This branch allows you to participate in social tasks to help build awareness of AIBC and its decentralized products. Specifically, you will be able to engage with promoting our ecosystem within Tiktok and Instagram.</li>
                                        <li><b>Earn Rewards: </b>At the end of the campaign, you can redeem your earned USDC or choose to receive AIBC DAO Tokens instead. At the time of your withdrawal you will be able to choose whichever payment option is preferred by you. Once the selection is made your funds will be credited to your wallet instantly.</li>
                                        <li>Please note, we encourage community participation and engagement. Therefore, anyone looking to simply sell out of the token at the end of the campaign should choose to collect their earnings in USDC. For long term believers in the project, we welcome you to convert your earnings into the AIBC DAO Token at the launch valuation so you can be part of the revolution.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AirdropBoxes />
            <div className='terms-of-service my-5'>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Be Part of the Change</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>With the AIBC Token, you’re not just claiming free tokens—you’re joining a global movement to revolutionize the way technology serves humanity. From decentralized social media platforms to transparent supply chains, your participation fuels the advancement of ethical and innovative solutions.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>About the AIBC Foundation</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>The AIBC Foundation Inc. is a 501(c)(3) registered non-profit organization dedicated to advancing the ethical application of AI, blockchain, and cybersecurity. Our goal is to create a transparent and decentralized future that benefits everyone.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Act Now! Limited Tokens Available</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>The AIBC Airdrop is available for a limited time, and tokens are distributed on a first-come, first-served basis. Don’t miss your chance to claim your stake in the future of decentralized innovation.<br />
                                    Claim Your AIBC Tokens Today!<br />
                                    Join the campaign now and become a driving force for change. Together, let’s build a future powered by transparency, trust, and cutting-edge technology.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Airdrop
