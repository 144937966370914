import React from 'react'

const Tokenomics = () => {
    return (
        <>
            <div className='terms-of-service my-5 pt-5'>
                <h1 className="my-3">Tokenomics: <br />The AIBC DAO Governance Token</h1>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div style={{ textAlign: 'justify' }}>The AIBC Foundation operates as a decentralized autonomous organization (DAO) governed by the AIBC DAO Token.</div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Key Features:</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>
                                    <ul>
                                        <li><b>Token Standard: </b>ERC20 on Polygon.</li>
                                        <li><b>Fixed Supply: </b>150 million tokens.</li>
                                        <li><b>Current Price: </b>$0.10 per token.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Distribution Breakdown:</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>
                                    <ul>
                                        <li><b>Developer Allocation: </b>50M</li>
                                        <li><b>Liquidity Pool: </b>25M</li>
                                        <li><b>Insiders: </b>2.5M</li>
                                        <li><b>Airdrop: </b>2.5M</li>
                                        <li><b>Future Release: </b>70M (10M tokens annually) at management discretion.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Fund Allocation:</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>
                                    <ul>
                                        <li><b>Community Voted Proposals: </b>75%</li>
                                        <li><b>Operating Expenses: </b>20%</li>
                                        <li><b>Liquidity Pool: </b>5%</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Deflationary Mechanism:</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>
                                    <div>
                                        <div>A 5% royalty on token sales supports liquidity, token burn, and AIBC income, ensuring sustainable growth.</div>
                                        <div>Become part of the AIBC ecosystem and drive meaningful change through innovation, transparency, and decentralization.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tokenomics
