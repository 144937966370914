import React, { useState } from 'react';

import Portfolio from '../../components/Stake/Portfolio'
import Statistics from '../../components/Stake/Statistics'
import Transactions from '../../components/Stake/Transactions'

import '../../assets/css/stake.css'

const Stake = () => {
    return (
        <div className='stake-container'>
            <div className='grid-container'>
                <main>
                    <Statistics />
                    <Portfolio />
                    <Transactions />
                </main>
            </div>
        </div>
    );
};

export default Stake;