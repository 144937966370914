import React from 'react';
import { NavLink } from 'react-router-dom';

const ImpactSection = () => {
    return (
        <>

            <div className="impact-container mt-5">
                {/* <img src={img} className='img-fluid impact-background' alt='background-img' /> */}
                {/* <img src={leaf} className='img-fluid leaf1' alt='background-img' />
                <img src={leaf} className='img-fluid leaf2' alt='background-img' /> */}
                    <div className='container'>
                <div className="big-green-impact my-5 d-flex flex-column align-items-center">
                        <h2><span>Our Mission:<br /> Driving Ethical Innovation</span></h2>
                        <p>Our mission is to promote, oversee, and advance the global development of ethical and responsible applications of artificial intelligence (AI), blockchain, and cybersecurity technologies. By championing these efforts, we aim to create a future where innovation serves humanity's best interests.</p>
                        <div className="hero-buttons d-flex flex-column" style={{ width: 'fit-content' }}>
                            <div className=' d-flex gap-4'>
                                <NavLink to="/our-philosophy" className="btn btn-first">Our Philosophy</NavLink>
                                <NavLink to="/donate" className="btn btn-first">Donate Now</NavLink>
                            <NavLink to="/whitepaper" className="btn btn-first">Whitepaper</NavLink>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ImpactSection;
