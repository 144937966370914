import React, { useMemo } from 'react'
import { formatUnits, parseUnits } from 'ethers'

// import { portfolioData } from '../../../../data'

import { useStatisticsData } from '../../../../contexts/statisticsContext'
import { getPortfolioData } from '../../../../utils/utils'
import PortfolioCard from './PortfolioCard'
import './index.css'

const MyPortfolio = ({ isLoading, setIsLoading }) => {
    const { staking30CurrentRound, staking60CurrentRound, staking90CurrentRound, staking180CurrentRound, 
        staking30Rounds, staking60Rounds, staking90Rounds, staking180Rounds, balancesData,
        staking30StakingInfo, staking60StakingInfo, staking90StakingInfo, staking180StakingInfo
    } = useStatisticsData();
    let portfolioData = [];

    let portfolio30Data = getPortfolioData(staking30StakingInfo, staking30Rounds, 30);
    let portfolio60Data = getPortfolioData(staking60StakingInfo, staking60Rounds, 60);
    let portfolio90Data = getPortfolioData(staking90StakingInfo, staking90Rounds, 90);
    let portfolio180Data = getPortfolioData(staking180StakingInfo, staking180Rounds, 180);

    portfolioData = portfolioData.concat(portfolio30Data).concat(portfolio60Data).concat(portfolio90Data).concat(portfolio180Data);
    let totalStakedAmount = 0;
    for (let i = 0; i < portfolioData.length; i ++) {
        if (Number(formatUnits(portfolioData[i].withdrawAmount, 6)) == 0) {
            totalStakedAmount += Number(formatUnits(portfolioData[i].stakedAmount, 6));
        }
    }
    
    return (
        <div className='myportfolio-container'>
            <div className='card-title-wrapper'>
                <div className='card-title'>
                    My Portfolio
                </div>
                <div className='my-deposit'>
                    ${ totalStakedAmount }
                </div>
            </div>
            <div className='card-content'>
                <div className='card-content-wrapper'>
                    {
                        portfolioData.map((item, index) => (
                            <PortfolioCard item={item} key={index}></PortfolioCard>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default MyPortfolio