import React, { useEffect } from 'react';

const WhitePaper = () => {
  useEffect(() => {
    window.location.href = '/AIBC_Whitepaper_Report.pdf';
  }, []);

  return (
    <div className=' d-flex justify-content-center align-items-center' style={{minHeight: '36.5vh'}}>
      <p className=' mt-5'>
        Your white paper is downloading. If the download does not occur automatically, 
        please <a href="/AIBC_Whitepaper_Report.pdf" download="AIBC_Whitepaper_Report.pdf">click here</a>.
      </p>
    </div>
  );
};

export default WhitePaper;
