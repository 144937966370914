import { STAKING_STATUS } from "../constants";
import { formatUnits } from "ethers";

export const formatAddress = (address) => {
    return address? `${address.substring(0, 6)}...${address.substring(address.length-5, address.length)}` : '';
}

export const getStatusText = (status) => {
    if (status == 0) {
        return 'pending ratification';
    }
    else if (status == 1) {
        return 'active';
    }
    else if (status == 2) {
        return 'closed';
    }
    else if (status == 3) {
        return 'resolved';
    }
    else if (status == 4) {
        return 'cancelled';
    }
    else if (status == 5) {
        return 'quorum_failed';
    }
    return '';
}

export const getCurrentRound = (roundIndex, rounds) => {
    if (rounds.length == 0) { 
        return {
            index: 0,
            startTime: '',
            stakedAmount: 0,
            status: 0
        }; 
    }
    const round = {};
    round.index = roundIndex;
    round.startTime = (new Date(Number(rounds[roundIndex - 1].startTime) * 1000)).toLocaleString();
    round.stakedAmount = Number(formatUnits(rounds[roundIndex - 1].stakedAmount, 6));
    round.status = Number(rounds[roundIndex - 1].status);

    return round;
}

export const getPortfolioData = (stakingInfo, roundsInfo, lockPeriod) => {
    if (stakingInfo.length < 1) return [];
    if (roundsInfo.length < 1) return [];

    const portfolioList = [];
    for (let i = 0; i < stakingInfo.length; i ++) {
        const portfolio = {};
        portfolio.index = Number(stakingInfo[i].roundId);
        portfolio.startTime = (new Date(Number(roundsInfo[portfolio.index - 1].startTime) * 1000)).toLocaleString();
        const lockTime = Number(roundsInfo[portfolio.index - 1].lockTime);
        portfolio.lockTime = lockTime == 0 ? '' : (new Date(lockTime * 1000)).toLocaleString();
        portfolio.lockPeriod = lockPeriod;
        portfolio.roundStakedAmount = roundsInfo[portfolio.index - 1].stakedAmount;
        portfolio.stakedAmount = stakingInfo[i].stakedAmount;
        portfolio.withdrawAmount = stakingInfo[i].withdrawAmount;
        portfolio.status = Number(roundsInfo[portfolio.index - 1].status);
        portfolioList.push(portfolio);
    }

    return portfolioList;
}

export const getRecentRounds = (roundIndex, rounds, lockPeriod) => {
    if (rounds.length == 0) { 
        return []; 
    }
    const recentRounds = [];
    if (roundIndex >= 2) {
        const round = {};
        round.index = `${lockPeriod} - ${roundIndex - 1}`;
        round.startTime = (new Date(Number(rounds[roundIndex - 2].startTime) * 1000)).toLocaleString();
        round.lockTime = (new Date(Number(rounds[roundIndex - 2].lockTime) * 1000)).toLocaleString();
        round.lockPeriod = lockPeriod;
        round.stakedAmount = Number(formatUnits(rounds[roundIndex - 2].stakedAmount, 6)).toLocaleString();
        round.returnedAmount = Number(formatUnits(rounds[roundIndex - 2].returnedAmount, 6)).toLocaleString();
        round.orderId = rounds[roundIndex - 2].orderId;
        round.invoiceId = rounds[roundIndex - 2].invoiceId;
        round.skuNumber = rounds[roundIndex - 2].skuNumber;
        round.status = rounds[roundIndex - 2].status;

        recentRounds.push(round);
    }

    const round = {};
    round.index = `${lockPeriod} - ${roundIndex}`;
    round.startTime = (new Date(Number(rounds[roundIndex - 1].startTime) * 1000)).toLocaleString();
    round.lockTime = (new Date(Number(rounds[roundIndex - 1].lockTime) * 1000)).toLocaleString();
    round.lockPeriod = lockPeriod;
    round.stakedAmount = Number(formatUnits(rounds[roundIndex - 1].stakedAmount, 6)).toLocaleString();
    round.returnedAmount = Number(formatUnits(rounds[roundIndex - 1].returnedAmount, 6)).toLocaleString();
    round.orderId = rounds[roundIndex - 1].orderId;
    round.invoiceId = rounds[roundIndex - 1].invoiceId;
    round.skuNumber = rounds[roundIndex - 1].skuNumber;
    round.status = rounds[roundIndex - 1].status;

    recentRounds.push(round);

    return recentRounds;
}

export const getStakingStatusText = (status) => {
    if (status === STAKING_STATUS.STAKING) {
        return 'STAKING';
    }
    else if (status === STAKING_STATUS.ORDER_ID) {
        return "ORDER_ID";
    }
    else if (status === STAKING_STATUS.INVOICE_ID) {
        return "INVOICE_ID";
    }
    else if (status === STAKING_STATUS.SKU_NUMBER) {
        return "SKU_NUMBER";
    }
    else if (status === STAKING_STATUS.RETURNED) {
        return "RETURNED";
    }
}

export const getRewardRate = (lockPeriod) => {
    if (lockPeriod === 30) {
        return 10;
    }
    else if (lockPeriod === 60) {
        return 25;
    }
    else if (lockPeriod === 90) {
        return 50;
    }
    else if (lockPeriod === 180) {
        return 100;
    }
}