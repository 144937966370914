import React, { useState, useEffect, useMemo } from 'react'
import { formatUnits, parseUnits } from 'ethers'

import StakingCard from './StakingCard'

import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react'
import { useStatisticsData } from '../../../../contexts/statisticsContext'
import { getCurrentRound } from '../../../../utils/utils'
import './index.css'

const Purchase = ({ isLoading, setIsLoading }) => {
    const { address, isConnected } = useWeb3ModalAccount();
    const { staking30CurrentRound, staking60CurrentRound, staking90CurrentRound, staking180CurrentRound, 
        staking30Rounds, staking60Rounds, staking90Rounds, staking180Rounds, balancesData
    } = useStatisticsData();

    const currentRound30 = getCurrentRound(staking30CurrentRound, staking30Rounds);
    const currentRound60 = getCurrentRound(staking60CurrentRound, staking60Rounds);
    const currentRound90 = getCurrentRound(staking90CurrentRound, staking90Rounds);
    const currentRound180 = getCurrentRound(staking180CurrentRound, staking180Rounds);

    const usdcBalance = Number(formatUnits(balancesData.usdcBalance, 6)).toLocaleString();

    const locks = [
        {
            index: currentRound30.index,
            startTime: currentRound30.startTime,
            lockPeriod: 30,
            stakedAmount: currentRound30.stakedAmount,
            status: currentRound30.status
        },
        {
            index: currentRound60.index,
            startTime: currentRound60.startTime,
            lockPeriod: 60,
            stakedAmount: currentRound60.stakedAmount,
            status: currentRound60.status
        },
        {
            index: currentRound90.index,
            startTime: currentRound90.startTime,
            lockPeriod: 90,
            stakedAmount: currentRound90.stakedAmount,
            status: currentRound90.status
        },
        {
            index: currentRound180.index,
            startTime: currentRound180.startTime,
            lockPeriod: 180,
            stakedAmount: currentRound180.stakedAmount,
            status: currentRound180.status
        },
    ]

    return (
        <div className='purchase-container'>
            <div className='card-title-wrapper'>
                <div className='card-title'>
                    Stake
                </div>
                <div className='token-card-price'>
                    ${usdcBalance}
                </div>
            </div>
            <div className='card-content'>
                <div className='card-content-wrapper'>
                    {
                        locks.map((item, index) => (
                            <StakingCard item={item} key={index}></StakingCard>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Purchase