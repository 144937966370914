import React, { useEffect, useState } from 'react';
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react'
import { formatUnits, parseUnits } from 'ethers';

import { useActions } from '../hooks/useActions';
import { useStatisticsData } from '../contexts/statisticsContext';

const PaymentForm = () => {
    const [donationAmount, setDonationAmount] = useState('15');
    const [currency, setCurrency] = useState('usd');
    const [customAmount, setCustomAmount] = useState('');
    const [isCustomAmount, setIsCustomAmount] = useState(false);

    const [usdcAmount, setUsdcAmount] = useState(15);
 
    const { approveUSDCToDonation, donate } = useActions();
    const { balancesData } = useStatisticsData();

    const usdcBalance = Number(formatUnits(balancesData.usdcBalance, 6));
    const usdcAllowance = Number(formatUnits(balancesData.usdcAllowance, 6));
    const { address, isConnected } = useWeb3ModalAccount();

    const handleCustomAmountClick = () => {
        setIsCustomAmount(true);
        setDonationAmount('other');
    };

    useEffect(() => {
        if (isCustomAmount) {
            setUsdcAmount(parseInt(customAmount));
        } else {
            setUsdcAmount(parseInt(donationAmount));
        }
    }, [isCustomAmount, customAmount, donationAmount])

    const onApprove = async () => {
        if (!isConnected) return;

        const usdcAmount = isCustomAmount? parseInt(customAmount) : parseInt(donationAmount);

        if (usdcAllowance < usdcAmount) {
            const approveStatus = await approveUSDCToDonation(balancesData.usdcBalance);
            if (approveStatus.success) {
                console.log(approveStatus.message);
            } else {
                console.log(approveStatus.message);
                return;
            }
        }
    }

    const onDonate = async () => {
        if (!isConnected) return;

        try {
            const status = await donate(parseUnits(usdcAmount.toFixed(2), 6));
            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='donate-now-form-container'>
            <div className="payment-form">
                <div className='payment-form-header text-center'>
                    <h1>DONATE NOW</h1>
                    <p>Donate to support community-directed initiatives that grow home, school, and community across the country. </p>
                </div>
                {/* <div className="donation-type">
                    <button
                        onClick={() => setDonationType('one-time')}
                        className={donationType === 'one-time' ? 'active' : ''}
                    >
                        One-time
                    </button>
                    <button
                        onClick={() => setDonationType('recurring')}
                        className={donationType === 'recurring' ? 'active' : ''}
                    >
                        Recurring
                    </button>
                </div> */}
                {/* {donationType === 'recurring' && (
                    <div className="donation-frequency">
                        <div className='d-flex flex-column' style={{ width: '60%' }}>
                            <label>Donation frequency</label>
                            <select
                                value={frequency}
                                onChange={(e) => setFrequency(e.target.value)}
                            >
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly (every 3 months)</option>
                                <option value="semi-annually">Semi-annually (every 6 months)</option>
                                <option value="yearly">Yearly (every 12 months)</option>
                            </select>
                        </div>
                    </div>
                )} */}

                <div className="donation-amount">
                    <button
                        onClick={() => {
                            setDonationAmount('15');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '15' ? 'active' : ''}
                    >
                        $15
                    </button>
                    <button
                        onClick={() => {
                            setDonationAmount('50');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '50' ? 'active' : ''}
                    >
                        $50
                    </button>
                    <button
                        onClick={() => {
                            setDonationAmount('100');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '100' ? 'active' : ''}
                    >
                        $100
                    </button>
                    <button
                        onClick={() => {
                            setDonationAmount('250');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '250' ? 'active' : ''}
                    >
                        $250
                    </button>

                    {isCustomAmount ? (
                        <input
                            type="text"
                            value={customAmount}
                            placeholder="Enter amount"
                            onChange={(e) => setCustomAmount(e.target.value)}
                            onBlur={() => setDonationAmount(customAmount)}
                        />
                    ) : (
                        <button
                            onClick={handleCustomAmountClick}
                            className={donationAmount === 'other' ? 'active' : ''}
                        >
                            $ Other
                        </button>
                    )}
                    <select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                    >
                        <option value="usd">USDC</option>
                    </select>
                </div>
                <hr className='my-5' />
                <h4>USDC Balance: {usdcBalance} USDC</h4>
                <hr className='my-5' />
                {/* <h3>Payment Details</h3> */}
                <div className="payment-info row m-0">
                    {/* <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Card Number *</label>
                        <input
                            type="text"
                            placeholder="Card Number"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-3 col-md-3 col-sm-6 col-6">
                        <label>Expiry Date *</label>
                        <input
                            type="text"
                            placeholder="MM/YY"
                            value={expiryDate}
                            onChange={(e) => setExpiryDate(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-3 col-md-3 col-sm-6 col-6">
                        <label>CVC *</label>
                        <input
                            type="text"
                            placeholder="CVC"
                            value={cvc}
                            onChange={(e) => setCVC(e.target.value)}
                        />
                    </div>
                    <p className="terms">
                        By donating, you agree to our <a href="#">Privacy Policy</a> and <a href="#">Classy’s Terms</a> and Privacy Policy. Classy facilitates your donations.
                    </p>
                    <div className="cover-fees">
                        <input
                            type="checkbox"
                            checked={coverFees}
                            onChange={(e) => setCoverFees(e.target.checked)}
                        />
                        <label>I’d like to cover all transaction fees so 100% of my donation goes to Big Green.</label>
                    </div> */}
                    { (isConnected && usdcAllowance < usdcAmount) && <button className='give-now' onClick={onApprove}>Approve USDC</button> }
                    { isConnected && <button className="give-now" onClick={onDonate}>GIVE NOW!</button> }
                </div>
            </div>
        </div>
    )
}

export default PaymentForm;
