import React from 'react';

const SecuritySection = () => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2>HOW TO ENGAGE</h2>
                <h3>Become a Catalyst for Positive Change</h3>
                <p>Engage with AIBC DAO through our dynamic and inclusive community structure. Explore the four vibrant pathways to contribute and grow </p>
                <div className="features">
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <h3>Events: <br/>Building a Thought Leadership Community</h3>
                        <p className='m-0'>Participate in exclusive AIBC events designed to foster innovation and leadership. Members connect with experts and visionaries shaping the future of AI, blockchain, and cybersecurity.
                        </p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg2.svg" alt="PCI-compliant servers" />
                        <h3>Shopping: <br/>Exclusive Member Benefits</h3>
                        <p>Enjoy a 10% lifetime discount on all purchases from Krizaar.com, a blockchain-based multivendor marketplace, when you become an AIBC member.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg3.svg" alt="Payment data encryption" />
                        <h3>Staking: <br/>Maximize Rewards Transparently</h3>
                        <p>AIBC members can access real-world asset staking pools, delivering exceptional rewards with unmatched transparency, helping to finance inventory efficiently.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <h3>Token Swap: <br/>Seamless, Cost-Free Cross-Chain Swapping</h3>
                        <p className='m-0'>Enjoy unlimited cross-chain token swaps with zero transaction fees. Coming soon: flash loan liquidity protocols to provide competitive pricing and instant access to liquidity.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecuritySection;
