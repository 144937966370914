import React from "react";
import { NavLink } from "react-router-dom";
const JoinTheCommunity = () => {
    return (
        <section className="how-it-works-section">
            <div className="how-it-works-header mb-3">
                <span className="badge">JOIN THE COMMUNITY</span>
                <p>
                    Join a fast-growing community of developers and innovators connected all over the world, building the new era of the internet.
                </p>
            </div>
            <div className="boxes-main-div d-flex justify-content-around mx-5">
                <div className="how-it-works-box mb-5 px-5 d-flex align-items-center gap-4">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"><title>twitter-x</title><path d="m13.595 10.689 6.241-7.255h-1.479l-5.419 6.3-4.328-6.3H3.617l6.546 9.526-6.546 7.608h1.48l5.722-6.653 4.571 6.653h4.993l-6.788-9.88Zm-2.026 2.354-.663-.948-5.277-7.548h2.272l4.258 6.091.664.949 5.535 7.918h-2.272l-4.517-6.461Z" fill="currentColor"></path></svg>
                    Twitter(X)
                </div>
                <div className="how-it-works-box mb-5 px-5 d-flex align-items-center gap-4">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"><title>telegram</title><path d="M11.99 22.001c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10Zm-3.11-8.83.013-.007.87 2.87c.112.311.266.367.453.341.188-.025.287-.126.41-.244l1.188-1.148 2.55 1.888c.466.257.8.124.917-.432l1.657-7.822c.183-.728-.137-1.02-.702-.788l-9.733 3.76c-.664.266-.66.638-.12.803l2.497.78v-.001Z" fill="currentColor"></path></svg>
                    Telegram
                </div>
                <div className="how-it-works-box mb-5 px-5 d-flex align-items-center gap-4">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"><title>youtube</title><path fill-rule="evenodd" clip-rule="evenodd" d="M21.015 4.763c1.01.258 1.807 1.011 2.079 1.967.505 1.746.485 5.385.485 5.385s0 3.62-.485 5.366c-.272.956-1.069 1.71-2.079 1.967-1.845.46-9.225.46-9.225.46s-7.362 0-9.226-.479c-1.01-.257-1.807-1.01-2.078-1.966C0 15.735 0 12.096 0 12.096s0-3.62.486-5.366c.271-.956 1.087-1.728 2.078-1.985 1.845-.46 9.226-.46 9.226-.46s7.38 0 9.226.478Zm-5.438 7.334L9.44 15.442v-6.69l6.137 3.345Z" fill="currentColor"></path></svg>
                    Youtube
                </div>
            </div>
        </section>
    );
};

export default JoinTheCommunity;
