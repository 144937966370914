import React, { useState, useEffect, useMemo } from 'react'
import { formatUnits, parseUnits } from 'ethers'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useStatisticsData } from '../../../../../contexts/statisticsContext'
import { useActions } from '../../../../../hooks/useActions'
import { getStakingStatusText } from '../../../../../utils/utils'
import './index.css'

const StakingCard = ({ item }) => {
    const { balancesData } = useStatisticsData();
    const { approveUSDCToStakingRewards, stakeToStakingRewards } = useActions();
    const [amount, setAmount] = useState(1000);
    const { address, isConnected } = useWeb3ModalAccount();

    const usdcAllowance = useMemo(() => {
        if (item.lockPeriod === 30) {
            return Number(formatUnits(balancesData.stakingRewards30Allowance, 6));
        }
        else if (item.lockPeriod === 60) {
            return Number(formatUnits(balancesData.stakingRewards60Allowance, 6));
        }
        else if (item.lockPeriod === 90) {
            return Number(formatUnits(balancesData.stakingRewards90Allowance, 6));
        }
        else if (item.lockPeriod === 180) {
            return Number(formatUnits(balancesData.stakingRewards180Allowance, 6));
        }
    }, [balancesData, item]);
    const usdcBalance = Number(formatUnits(balancesData.usdcBalance, 6));

    const setStakeAmount = (value) => {
        if (value > usdcBalance) {
            setAmount(usdcBalance);
        } else {
            setAmount(value);
        }
    }

    const onApprove = async () => {
        if (!isConnected) return;

        if (usdcAllowance < amount) {
            const approveStatus = await approveUSDCToStakingRewards(parseUnits(usdcBalance.toFixed(2), 6), item.lockPeriod);
            if (approveStatus.success) {
                console.log(approveStatus.message);
            } else {
                console.log(approveStatus.message);
                return;
            }
        }
    }

    const onStake = async () => {
        if (!isConnected) return;
        if (usdcAllowance < amount) return;

        try {
            const status = await stakeToStakingRewards(item.index, parseUnits(amount.toFixed(2), 6), item.lockPeriod);
            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='stake-card-container'>
            <div className='stake-card-title'>
                {item.lockPeriod} Days Lock
            </div>
            <div className='stake-card-content'>
                <div className='stake-card-stat'>
                    <div className='stake-card-stat-title'>Round Index</div>
                    <div className='stake-card-stat-value'>{item.index}</div>
                </div>
                <div className='stake-card-stat'>
                    <div className='stake-card-stat-title'>Start Time</div>
                    <div className='stake-card-stat-value'>{item.startTime}</div>
                </div>
                <div className='stake-card-stat'>
                    <div className='stake-card-stat-title'>Lock Period</div>
                    <div className='stake-card-stat-value'>{item.lockPeriod}</div>
                </div>
                <div className='stake-card-stat'>
                    <div className='stake-card-stat-title'>Staked Amount</div>
                    <div className='stake-card-stat-value'>{item.stakedAmount.toLocaleString()}</div>
                </div>
                <div className='stake-card-stat'>
                    <div className='stake-card-stat-title'>Status</div>
                    <div className='stake-card-stat-value'>{getStakingStatusText(item.status)}</div>
                </div>
            </div>
            <div className='stake-card-button-row'>
                <div className='stake-card-input-container'>
                    <div className="stake-input-container">
                        <input className="stake-input-field" type="text" placeholder="500" value={amount} onChange={(e) => setStakeAmount(Number(e.target.value))}/>
                        <span className="stake-currency">USDC</span>
                    </div>
                    { amount > usdcAllowance && <button className='stake-card-button approve' onClick={onApprove}>Approve</button> }
                </div>
                <button className='stake-card-button stake' onClick={onStake}>Stake</button>
            </div>
        </div>
    )
}

export default StakingCard;