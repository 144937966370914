import React, { useState } from 'react';
import { FaArrowLeft, FaEdit, FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { uploadToPinata } from '../../api/pinata';
import { useStatisticsData } from '../../contexts/statisticsContext';
import { useActions } from '../../hooks/useActions';
import { useBlockNumber } from '../../hooks/useBlockNumber';
import { formatUnits, parseUnits } from 'ethers';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';

const CreateGrantmakingProposal = () => {
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [minimumSize, setMinimumSize] = useState('');
    const [maximumSize, setMaximumSize] = useState('');
    const [maxSize, setMaxSize] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [amount, setAmount] = useState(0);
    const [showPreview, setShowPreview] = useState(false);

    const { balancesData } = useStatisticsData();
    const { getBlockNumber } = useBlockNumber();

    const aibcBalance = Number(formatUnits(balancesData.aibcBalance, 18));
    const stakedBalance = Number(formatUnits(balancesData.stakedBalance, 18));
    const stakedAllowance = Number(formatUnits(balancesData.stakedAllowance, 18));

    const { address, isConnected } = useWeb3ModalAccount();
    const { approveAIBCToStaking, stakeAIBC, createProposal } = useActions();

    const handlePreviewChange = () => {
        setShowPreview(!showPreview)
    }

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }

    const onSubmitProposal = async () => {
        if (!isConnected) return;
        if (stakedBalance < 100) return;

        if (title == '' || summary == '' || minimumSize == '' || maximumSize == '' || maxSize == '') return;

        const proposalData = {
            type: 'Grantmaking',
            title: title,
            summary: summary,
            minimumSize: minimumSize,
            maximumSize: maximumSize,
            maxSize: maxSize,
            startDate: startDate,
            endDate: endDate
        }

        try {

            const proposalCID = await uploadToPinata(proposalData);
            
            const startTimestamp = (new Date(startDate)).getTime() / 1000;
            const endTimeStamp = (new Date(endDate)).getTime() / 1000;
            const { delay, duration } = await getBlockNumber(startTimestamp, endTimeStamp);

            const status = await createProposal(proposalCID, delay, duration);

            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }       
    }

    const onApprove = async () => {
        if (!isConnected) return;

        if (stakedAllowance < amount) {
            const approveStatus = await approveAIBCToStaking(balancesData.aibcBalance);
            if (approveStatus.success) {
                console.log(approveStatus.message);
            } else {
                console.log(approveStatus.message);
                return;
            }
        }
    }

    const onStake = async () => {
        if (!isConnected) return;
        if (stakedAllowance < amount) return;

        try {
            const status = await stakeAIBC(parseUnits(amount.toFixed(2), 18));
            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='select-proposal'>
            <div className="proposal-container">
                <div className="create-proposal-container">
                    <div className="create-proposal-header">
                        <button className="back-button" onClick={handleBack}><FaArrowLeft /></button>
                        <span className="create-proposal-header-text">New Grantmaking Proposal</span>
                    </div>
                    <div className='create-proposal-information'>
                        <p className="grantmaking-badge">Grantmaking</p>
                        <p className='proposal-info mb-4'>
                        A Grantmaking Proposal is a prerequisite for launching of a "Grants Round", which are held quarterly, throughout the fiscal year. This proposal will establish the parameters of each grants round, including, total available budget, as well as minimum and maximum grant sizes. Once passed, a call for applicants will be released and a new Grants Round will be opened in the DAO's Grants Portal.
                        </p>
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter the question you would like to ask the community"
                        />
                    </div>

                    <div className='preview-and-edit-button'>
                        {showPreview ? <button className='preview-button' onClick={handlePreviewChange}>Edit <FaEdit /></button> :
                            <button className='preview-button' onClick={handlePreviewChange}>Preview <FaEye /></button>
                        }
                    </div>
                    {!showPreview ?
                        <>
                            <div className="create-proposal-input-group">
                                <label>Description</label>
                                <textarea
                                    value={summary}
                                    onChange={(e) => setSummary(e.target.value)}
                                    placeholder="Describe any relevant grants details"
                                    rows={5}
                                />
                            </div>
                        </> : <></>}

                    <div className="create-proposal-input-group">
                        <label>Minnimum Grant Size (USD)</label>
                        <input
                            type="text"
                            value={minimumSize}
                            onChange={(e) => setMinimumSize(parseInt(e.target.value == ""? '0': e.target.value))}
                            placeholder="Must be greater than zero"
                        />
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Maximum Grant Size (USD)</label>
                        <input
                            type="text"
                            value={maximumSize}
                            onChange={(e) => setMaximumSize(parseInt(e.target.value == ""? '0': e.target.value))}
                            placeholder="Must be greater than minimum grant size"
                        />
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Max Grant Size (USD)</label>
                        <input
                            type="text"
                            value={maxSize}
                            onChange={(e) => setMaxSize(parseInt(e.target.value == ""? '0': e.target.value))}
                            placeholder="Must be greater than maximum grant size"
                        />
                    </div>
                    <div className="create-proposal-voting-period mb-5">
                        <label>Voting period</label>
                        <div className='create-proposal-period-selection'>
                            <div className='selection-item'>
                                <label>Start</label>
                                <input
                                    type="datetime-local"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className='selection-item'>
                                <label>End</label>
                                <input
                                    type="datetime-local"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="create-proposal-voting-period mb-5">
                        <label>Staking Information</label>
                        <div className='create-proposal-period-selection'>
                            <div className='selection-item'>
                                <label>Your Staked Amount: {stakedBalance} AIBC, Your AIBC Balance: {aibcBalance} AIBC</label>
                                <div className='create-proposal-stake'>
                                    <input
                                        type="text"
                                        className='create-proposal-stake-input'
                                        value={amount}
                                        onChange={(e) => setAmount(parseInt(e.target.value == ""? '0': e.target.value))}
                                    />
                                    {amount > stakedAllowance && <button className='create-proposal-submit-button approve' onClick={onApprove}>Approve</button>}
                                    <button className='create-proposal-submit-button' onClick={onStake}>Stake</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="create-proposal-submit-button" onClick={onSubmitProposal}>Submit Proposal</button>
                </div>
            </div>
        </div>
    );
};

export default CreateGrantmakingProposal;
