import React from 'react'

const WhatIsStaking = () => {
    return (
        <>
            <div className="what-staking">
                <div className="how-it-works-header">
                    <span className="badge">WHAT IS STAKING?</span>
                    <h2 className="how-it-works-title">
                    </h2>
                </div>
                <div className='what-staking-content'>
                    <div className='what-staking-image'>
                        <img src="/images/what-is-staking.png" className='img-fluid' alt='image' />
                    </div>
                    <div className='what-staking-inside-content'>
                        <h2>The Best Crypto Staking Platform</h2>
                        <p>Staking is a web3 mechanism via which participants can contribute their liquidity locked within smart contracts for a predetermined reward and for a predetermined duration.  Staking contracts are also referred to as staking pools interchangeably.</p>
                        <p>While staking contracts are still in their infancy, nevertheless, increasingly, new use cases are emerging that enable real world scenarios to be powered by liquidity generated through these staking pools.</p>
                        <p>To participate in our staking pools, we require everyone to complete their KYC prior to withdrawing their rewards.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatIsStaking
