import React from 'react';

const ProblemSolutions = () => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2>PROBLEMS</h2>
                <h3>Challenges in a Centralized World</h3>
                <p>Today's centralized systems create inefficiencies and inequities, including </p>
                <div className="features">
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <p className='m-0'>Consolidation of power and wealth.
                        </p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg2.svg" alt="PCI-compliant servers" />
                        <p>Lack of transparency in social media algorithms.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg3.svg" alt="Payment data encryption" />
                        <p>Rising marketing and advertising costs.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <p className='m-0'>Supply chain inefficiencies with costly intermediaries.
                        </p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg2.svg" alt="PCI-compliant servers" />
                        <p>Barriers to entry for startups due to opaque systems.</p>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <h2>OUR SOLUTION</h2>
                <h3>An Integrated Decentralized Ecosystem</h3>
                <p>The AIBC Foundation proposes a unified ecosystem that integrates social media, ecommerce, supply chains, and global payment systems on a decentralized ledger.equitably.<br/>
                <b>This solution: </b> </p>
                <div className="features">
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <p className='m-0'>Promotes transparency, leading to disintermediation and cost savings.
                        </p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg2.svg" alt="PCI-compliant servers" />
                        <p>Harnesses AI for predictive analytics and unparalleled efficiency.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg3.svg" alt="Payment data encryption" />
                        <p>Utilizes blockchain to democratize trust and decentralization.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <p className='m-0'>Enhances security through blockchain andcybersecurity technologies.
                        </p>
                    </div>
                </div>
                <p className='mt-5'>Such an ecosystem will generate significant economic value by streamlining processes and reducing costs.</p>
            </div>
            <div className='container my-5'>
                <h2>Ecosystem</h2>
                <h3>AIBC’s Revolutionary Platforms</h3>
                <p>The AIBC Foundation proposes a unified ecosystem that integrates social media, ecommerce, supply chains, and global payment systems on a decentralized ledger.equitably.<br/>
                <b>This solution: </b> </p>
                <div className="features">
                    <div className="feature-card">
                        <div className='feature-card-image d-flex justify-content-center align-items-center' style={{height: '100px'}}>
                        <img src="/images/shuffle.png" alt="3D Secure checkouts" />
                        </div>
                        <h3>Shuffle Hub</h3>
                        <p className='m-0'>A blockchain-powered social media platform enabling short-form video creation and live streaming. Integrated ecommerce features allow users to embrace the next generation of social commerce.
                        </p>
                    </div>
                    <div className="feature-card">
                        <div className='feature-card-image d-flex justify-content-center align-items-center' style={{height: '100px'}}>
                        <img src="/images/krizaar.jpg" alt="PCI-compliant servers" />
                        </div>
                        <h3>Krizaar</h3>
                        <p>A blockchain-based multivendor marketplace providing product and supply chain authenticity verification, ensuring transparency and protection against counterfeit goods.</p>
                    </div>
                    <div className="feature-card">
                        <div className='feature-card-image d-flex justify-content-center align-items-center' style={{height: '100px'}}>
                        <img src="/images/flobux.svg" alt="Payment data encryption" />
                        </div>
                        <h3>Flobux</h3>
                        <p>A blockchain-based freight management system addressing inefficiencies in global logistics. Flobux (mobile) and Digiloads (web) streamline freight tracking, documentationtransferance, and payment remittance for carriers and 3PLs. Beta release slated for mid-2025.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProblemSolutions;
