import React from 'react'
import { useStatisticsData } from '../../contexts/statisticsContext';

const TokenPrice = () => {
    const { tokenPrice } = useStatisticsData();

    return (
        <div className='token-price'>
            <span className='title'>AIBC Price</span>
            <span className='price'>${tokenPrice}</span>
        </div>
    )
}

export default TokenPrice;