import React, { useState, useEffect } from 'react'
import { getStatusText } from '../../utils/utils';
import { NavLink } from 'react-router-dom';
import { FaBars, FaRegClock } from 'react-icons/fa';

export const ProposalCard = ({ item, index }) => {
    return item && item.data && item.data.type ? (
        <NavLink to={`/proposals/vote/${item.data.type}/${index}`} style={{ color: 'black', textDecoration: 'none' }}>
            <div className="proposal">
                <h6>{item.data.title}</h6>
                <div className='d-flex' style={{ gap: '1rem' }}>
                    <p className={`proposal-index m-0`}>#{index + 1}</p>
                    <p className={`proposal-status ${getStatusText(item.status) == 'active' && 'active'} m-0`}>{getStatusText(item.status)}</p>
                    <p className='proposal-category m-0'>
                        {item?.data.type === 'Governance' ?
                            <span style={{
                                backgroundColor: '#9eb7ea',
                                border: '1px solid #658ae6',
                                color: 'black'
                            }}>Governance</span> :
                            item?.data.type === 'Grantmaking' ?
                                <span style={{
                                    backgroundColor: '#9edbc3',
                                    border: '1px solid #1b5d43',
                                    color: 'black'
                                }}>Grantmaking</span> :
                                item?.data.type === 'Membership' ?
                                    <span style={{
                                        backgroundColor: '#f6b796',
                                        border: '1px solid #ee7635',
                                        color: 'black'
                                    }}>Membership</span> : ''}
                    </p>
                    <p className='m-0 d-flex align-items-center' style={{ gap: '5px' }}><FaRegClock /> Voting started {(new Date(item.data.startDate)).toLocaleString()}</p>
                    <p className='m-0 d-flex align-items-center' style={{ gap: '5px' }}><FaRegClock /> {((new Date(item.data.endDate)) < (new Date())) ? 'Voting ended' : 'Voting is ending in'} {(new Date(item.data.endDate)).toLocaleString()}</p>
                </div>
            </div>
        </NavLink>
    ) : <></>
}