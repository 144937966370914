import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';

const projectId = '5f129ab4e93acba3af9d11b492723825';

const polygon = {
    chainId: 137,
    name: 'Polygon',
    currency: 'POL',
    explorerUrl: 'https://polygonscan.com',
    rpcUrl: 'https://polygon-mainnet.g.alchemy.com/v2/eMY6MFJNWoq7P3I5EnZTmqHqOPSphnAq'
}

const amoy = {
    chainId: 80002,
    name: 'Amoy',
    currency: 'AmoyPOL',
    explorerUrl: 'https://amoy.polygonscan.com',
    rpcUrl: 'https://polygon-amoy.g.alchemy.com/v2/eMY6MFJNWoq7P3I5EnZTmqHqOPSphnAq'
}

const metadata = {
    name: 'Aibc DAO',
    description: 'Aibc Governance DAO Platform.',
    url: 'https://aibcfoundation.org',
    icons: []
}

createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [polygon],
    projectId,
    enableAnalytics: true
})