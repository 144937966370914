import React, { useState, useEffect } from 'react'
import { formatUnits } from 'ethers'
import './index.css'

// import { ALTI_DECIMALS, USDT_DECIMALS } from 'constants'
import { useStatisticsData } from '../../../contexts/statisticsContext';

const Statistics = () => {
    const { stakingStatistics } = useStatisticsData();

    const totalStaked = Number(formatUnits(stakingStatistics.totalStaked, 6)).toLocaleString()
    const totalRewards = Number(formatUnits(stakingStatistics.totalRewards, 6)).toLocaleString()
    const totalRounds = Number(stakingStatistics.totalRounds).toLocaleString()
    const totalUsers = Number(stakingStatistics.totalUsers).toLocaleString()

    const cards = [
        {
            title: 'Total Value Locked on STAKING',
            value: `${ totalStaked } USDC`,
            className: ''
        },
        // {
        //     title: (
        //         <>
        //             USDC Balance on <span className='contract-name'>Staking</span>
        //         </>
        //     ),
        //     value: (
        //         <>
        //             { 100 } <div className="currency_type">USDC</div>
        //         </>
        //     ),
        //     className: 'green'
        // },
        {
            title: 'Total Rewards Returned',
            value: `${ totalRewards } USDC`,
            className: 'green'
        },
        {
            title: 'Total Users',
            value: `${totalUsers}`,
            className: ''
        },
        {
            title: 'Total Rounds',
            value: `${totalRounds}`,
            className: 'green'
        }
    ]

    return (
        <div className='statistics-container'>
            <div className="statistics-grid">
                {cards.map((card, index) => (
                    <div className={`overview-card ${card.className}`} key={index}>
                        <div className='card-title'>{card.title}</div>
                        <div className='card-value'>{card.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Statistics