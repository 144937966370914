import React from 'react';

const AirdropBoxes = () => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2>Exclusive Benefits of AIBC Token Holders</h2>
                <p>As an AIBC Token holder, you unlock access to:</p>
                <div className="features">
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <h3>Staking Rewards</h3>
                        <p className='m-0'>Maximize your returns with real-world asset staking pools. Enjoy transparency and competitive rewards.
                        </p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg2.svg" alt="PCI-compliant servers" />
                        <h3>Cross-Chain Token Swaps</h3>
                        <p>Enjoy unlimited, fee-free swaps across multiple blockchains. Flash loan liquidity protocols are coming soon!</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg3.svg" alt="Payment data encryption" />
                        <h3>NFT & Membership Perks</h3>
                        <p>Gain access to exclusive NFTs and membership benefits like lifetime discounts on Krizaar.com, a blockchain-based global ecommerce marketplace.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AirdropBoxes;
