import React from 'react'
import StakingPageHeroSection from './StakingPageHeroSection'
import HowItWorks from './HowItWorks'
import WhatIsStaking from './WhatIsStaking'
import JoinTheCommunity from './JoinTheCommunity'
import CoinsSlider from './CoinsSlider'

const Staking = () => {
  return (
    <>
      <StakingPageHeroSection/>
      <div className='separator-top'>
        <img src='/images/separator-top.svg'/>
      </div>
      <WhatIsStaking/>
      <div className='separator-top'>
        <img src='/images/separator-bottom.svg'/>
      </div>
      <HowItWorks/>
      <div className='separator-top'>
        <img src='/images/separator-top.svg'/>
      </div>
      <JoinTheCommunity/>
      <div className='separator-top'>
        <img src='/images/separator-bottom.svg'/>
      </div>
      <CoinsSlider/>
      {/* <div className='separator-top'>
        <img src='/images/separator-top.svg'/>
      </div> */}
    </>
  )
}

export default Staking
