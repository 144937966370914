import React from 'react'
import HeroSection from '../components/Home/Hero'
import ImpactSection from '../components/Home/Impact'
import CommunityGrantRoundSection from '../components/Home/CommunityGrantRound'
import MembershipResources from '../components/Home/MembershipResources'
import SecuritySection from '../components/Home/Security'
import { NavLink } from 'react-router-dom'

const Home = () => {
  return (
    <>
      <div className=''>
        <HeroSection />
        <ImpactSection />
        <div className="membership-resources text-dark pb-0  d-flex flex-column align-items-center mb-5">
          <div className="content">
            <h1>About Us</h1>
            <h2>AIBC Foundation - Advancing AI, Blockchain & Cybersecurity</h2>
            <p>
              The Artificial Intelligence, Blockchain & Cybersecurity (AIBC) Foundation Inc. is a registered 501(c)(3) non-profit organization in the United States. We are committed to fostering the responsible, ethical, and moral application of cutting-edge technologies for the betterment of humanity.
            </p>
          </div>
          <div className="hero-buttons d-flex flex-column" style={{ width: 'fit-content' }}>
            <div className=' d-flex gap-4'>
              <button to="" className="btn btn-first">Determination Letter</button>
            </div>
          </div>
        </div>
        <SecuritySection />
        <MembershipResources />
      </div>
    </>
  )
}

export default Home
