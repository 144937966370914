import React from 'react'
import { formatUnits, parseUnits } from 'ethers'
import { getStakingStatusText, getRewardRate } from '../../../../../utils/utils'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useActions } from '../../../../../hooks/useActions'
import { STAKING_STATUS } from '../../../../../constants'
import './index.css'

const PortfolioCard = ({ item }) => {
    const stakedAmount = Number(formatUnits(item.stakedAmount, 6));
    const roundStakedAmount = Number(formatUnits(item.roundStakedAmount, 6));
    const userWithdrawAmount = Number(formatUnits(item.withdrawAmount, 6));
    const statusText = getStakingStatusText(item.status);
    const { unstakeFromStakingRewards, withdrawFromStakingRewards } = useActions();

    const { address, isConnected } = useWeb3ModalAccount();

    const withdrawAmount = (stakedAmount * (100 + getRewardRate(item.lockPeriod))) / 100;
    const onUnstake = async () => {
        if (!isConnected) return;

        try {
            const status = await unstakeFromStakingRewards(item.index, parseUnits(stakedAmount.toFixed(2), 6), item.lockPeriod);
            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const onWithdraw = async () => {
        if (!isConnected) return;

        try {
            const status = await withdrawFromStakingRewards(item.index, parseUnits(withdrawAmount.toFixed(2), 6), item.lockPeriod);
            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='portfolio-card-container'>
            <div className='portfolio-card-title'>
                {item.lockPeriod} Days Lock
            </div>
            <div className='portfolio-card-content'>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Round Index</div>
                    <div className='portfolio-card-stat-value'>{item.index}</div>
                </div>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Start Time</div>
                    <div className='portfolio-card-stat-value'>{item.startTime}</div>
                </div>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Lock Time</div>
                    <div className='portfolio-card-stat-value'>{item.lockTime}</div>
                </div>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Lock Period</div>
                    <div className='portfolio-card-stat-value'>{item.lockPeriod}</div>
                </div>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Round Staked Amount</div>
                    <div className='portfolio-card-stat-value'>{roundStakedAmount.toLocaleString()}</div>
                </div>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Your Staked Amount</div>
                    <div className='portfolio-card-stat-value'>{stakedAmount.toLocaleString()}</div>
                </div>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Your Withdraw Amount</div>
                    <div className='portfolio-card-stat-value'>{userWithdrawAmount.toLocaleString()}</div>
                </div>
                <div className='portfolio-card-stat'>
                    <div className='portfolio-card-stat-title'>Status</div>
                    <div className='portfolio-card-stat-value'>{statusText}</div>
                </div>
            </div>
            <div className='portfolio-card-button-row'>
                <button className={`portfolio-card-button unstake ${item.status !== STAKING_STATUS.STAKING ? 'disabled' : ''}`} 
                    disabled={item.status !== STAKING_STATUS.STAKING} onClick={onUnstake}>Unstake</button>
                <button className={`portfolio-card-button withdraw ${(item.status !== STAKING_STATUS.RETURNED || withdrawAmount === userWithdrawAmount) ? 'disabled' : ''}`}
                    disabled={item.status !== STAKING_STATUS.RETURNED} onClick={onWithdraw}>Withdraw</button>
            </div>
        </div>
    )
}

export default PortfolioCard;