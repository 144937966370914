import React, { useState } from 'react';
import { FaBars, FaRegClock } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { PiCurrencyDollarSimple } from 'react-icons/pi';
import { RiGovernmentLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';

import { ProposalCard } from '../components/Proposal/ProposalCard';

import { useStatisticsData } from '../contexts/statisticsContext';
import { getStatusText } from '../utils/utils';

const categories = [
    { name: 'All', icon: <FaBars />, color: 'black' },
    { name: 'Governance', icon: <RiGovernmentLine />, color: 'blue' },
    { name: 'Grantmaking', icon: <PiCurrencyDollarSimple />, color: 'green' },
    { name: 'Membership', icon: <MdPeople />, color: 'orangered' }
];

const statuses = ['All', 'Active', 'Closed', 'Pending Ratification'];

const Proposals = () => {
    const [filterCategory, setFilterCategory] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [currentPage, setCurrentPage] = useState(0);

    const { proposalList } = useStatisticsData();

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };
    const reversedProposalList = [...proposalList].reverse()
    const filteredData = reversedProposalList.filter(item => {
        return (filterCategory === 'All' || item.data.type === filterCategory) &&
            (filterStatus === 'All' || getStatusText(Number(item.status)).toLowerCase() === filterStatus.toLowerCase());
    });

    const PER_PAGE = 5;
    const offset = currentPage * PER_PAGE;
    const currentPageData = filteredData.slice(offset, offset + PER_PAGE);
    const pageCount = Math.ceil(filteredData.length / PER_PAGE);

    return (
        <div className="proposals-container">
            <div className="top-bar">
                <div className='status'>
                    {statuses.map(status => (
                        <button
                            key={status}
                            className={filterStatus === status ? 'active' : ''}
                            onClick={() => {
                                setFilterStatus(status);
                                setCurrentPage(0);
                            }}
                        >
                            {status}
                        </button>
                    ))}
                </div>
                <button className="submit-new-proposal">
                    <NavLink to='/proposals/select' style={{ color: 'white', textDecoration: 'none' }}>Submit New Proposal</NavLink>
                </button>
            </div>
            <div className="proposals-container2">
                <div className="sidebar col-lg-3 col-md-6 col-sm-12 col-12">
                    <h6>FILTER BY CATEGORY</h6>
                    {categories.map(category => (
                        <button
                            key={category.name}
                            className={filterCategory === category.name ? 'active' : ''}
                            onClick={() => {
                                setFilterCategory(category.name);
                                setCurrentPage(0);
                            }}
                        >
                            <span style={{ color: category.color }}>
                                {category.icon}
                            </span>
                            {category.name}
                        </button>
                    ))}
                </div>
                <div className="proposals col-lg-9 col-md-6 col-sm-12 col-12">
                    <div className='proposals-pagination-top'>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                    <div className='mb-4'>
                        {currentPageData.map((item, index) => (
                            <ProposalCard key={index} item={item} index={item.id}></ProposalCard>
                        ))}
                    </div>
                    <div className='proposals-pagination'>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Proposals;