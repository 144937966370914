export const transactionHeader = [
    "Index", "Start Time", "Lock Time", "Lock Period", "Staked Amount", "Returned Amount", "Order ID", "Invoice ID", "SKU Number", "Status"
]

export const transactions = [
    {
        index: 1,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 1000,
        returnedAmount: 0,
        orderId: 'Order 1',
        invoiceId: 'Invoice 1',
        skuNumber: 'SKU 1',
        status: 'Staking'
    },
    {
        index: 1,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 1000,
        returnedAmount: 0,
        orderId: 'Order 1',
        invoiceId: 'Invoice 1',
        skuNumber: 'SKU 1',
        status: 'Staking'
    },
    {
        index: 1,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 1000,
        returnedAmount: 0,
        orderId: 'Order 1',
        invoiceId: 'Invoice 1',
        skuNumber: 'SKU 1',
        status: 'Staking'
    },
    {
        index: 1,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 1000,
        returnedAmount: 0,
        orderId: 'Order 1',
        invoiceId: 'Invoice 1',
        skuNumber: 'SKU 1',
        status: 'Staking'
    },
]

export const portfolioData = [
    {
        index: 1,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
    {
        index: 2,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
    {
        index: 3,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
    {
        index: 3,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
    {
        index: 4,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
    {
        index: 5,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
    {
        index: 6,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
    {
        index: 7,
        startTime: "2024/12/4 15:20:13",
        lockTime: "2024/12/4 16:20:12",
        lockPeriod: "30 days",
        stakedAmount: 100,
        status: 'Staking'
    },
]