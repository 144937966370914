import React, { useState } from 'react';
import { FaArrowLeft, FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useStatisticsData } from '../../contexts/statisticsContext';
import { useActions } from '../../hooks/useActions';
import { formatUnits, parseUnits } from 'ethers';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { getStatusText } from '../../utils/utils';

const VoteMembershipProposal = () => {
    const { id } = useParams();
    const [amount, setAmount] = useState(0);

    const { proposalList, proposalCount } = useStatisticsData();
    const { balancesData } = useStatisticsData();

    const currentProposal = proposalList[id];

    const aibcBalance = Number(formatUnits(balancesData.aibcBalance, 18));
    const stakedBalance = Number(formatUnits(balancesData.stakedBalance, 18));
    const stakedAllowance = Number(formatUnits(balancesData.stakedAllowance, 18));

    const { approveAIBCToStaking, stakeAIBC, voteToProposal } = useActions();
    const { address, isConnected } = useWeb3ModalAccount();

    let voteForPercent = 0;
    let voteAgainstPercent = 0;
    const voteFor = currentProposal? Number(formatUnits(currentProposal.voteFor, 18)) : 0;
    const voteAgainst = currentProposal? Number(formatUnits(currentProposal.voteAgainst, 18)) : 0;

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }

    const totalVotes = voteFor + voteAgainst;
    if (totalVotes != 0) {
        voteForPercent = voteFor / totalVotes * 100;
        voteAgainstPercent = 100 - voteForPercent;
    }

    const quorum = 10_000_000;
    const quorumPercent = totalVotes / quorum * 100;
    const restQuorumPercent = 100 - quorumPercent;

    const onVote = async (direction) => {
        if (!isConnected) return;
        if (stakedBalance < 100) return;

        try {
            const status = await voteToProposal(Number(id) + 1, direction);

            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }       
    }
    
    const onApprove = async () => {
        if (!isConnected) return;

        if (stakedAllowance < amount) {
            const approveStatus = await approveAIBCToStaking(balancesData.aibcBalance);
            if (approveStatus.success) {
                console.log(approveStatus.message);
            } else {
                console.log(approveStatus.message);
                return;
            }
        }
    }

    const onStake = async () => {
        if (!isConnected) return;
        if (stakedAllowance < amount) return;

        try {
            const status = await stakeAIBC(parseUnits(amount.toFixed(2), 18));
            if (status.success) {
                console.log(status.message);
            } else {
                console.log(status.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='select-proposal'>
            <div className="proposal-container">
                <div className="create-proposal-container">
                    <div className="create-proposal-header">
                        <button className="back-button" onClick={handleBack}><FaArrowLeft /></button>
                        <span className="create-proposal-header-text">{currentProposal && currentProposal.data.title}</span>
                    </div>
                    <div className='create-vote-information'>
                        <p className="membership-badge">Membership</p>
                        {currentProposal && <p className={`proposal-status ${getStatusText(currentProposal.status) == 'active' && 'active'}`}>{getStatusText(currentProposal.status)}</p>}
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Add/Remove Committee Member</label>
                        <p className='proposal-info mb-4'>
                            {currentProposal && currentProposal.data.commitee}
                        </p>
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Description</label>
                        <p className='proposal-info mb-4'>
                            {currentProposal && currentProposal.data.summary}
                        </p>
                    </div>
                    
                    <div className="create-proposal-input-group">
                        <label>Voting information</label>
                        <div className='voting-info-container'>
                            <div className='voting-info-information'>
                                <div className='title'>
                                    <h4>Information</h4>
                                </div>
                                <div className='content'>
                                    <div>
                                        <b>Voting System</b>
                                        <span>Basic voting</span>
                                    </div>
                                    <div>
                                        <b>Start date</b>
                                        <span>{currentProposal && (new Date(currentProposal.data.startDate)).toLocaleString()}</span>
                                    </div>
                                    <div>
                                        <b>End date</b>
                                        <span>{currentProposal && (new Date(currentProposal.data.endDate)).toLocaleString()}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='voting-info-result'>
                                <div className='title'>
                                    <h4>Results</h4>
                                </div>
                                <div className='content'>
                                    <div className="vote-item">
                                        <div className="vote-item-title">
                                            <div className='title-left'>For</div>
                                            <div className='title-right'>{voteFor / 1000}K AIBC {voteForPercent.toFixed(2)}%</div>
                                        </div>
                                        <div className='vote-item-progress'>
                                            <div className="progress-placeholder"></div>
                                            <div className='progress-full' style={{ width: `${voteForPercent}%`}}></div>
                                            <div className='progress-empty' style={{ width: `${voteAgainstPercent}%`}}></div>
                                        </div>
                                    </div>
                                    <div className="vote-item">
                                        <div className="vote-item-title">
                                            <div className='title-left'>Against</div>
                                            <div className='title-right'>{voteAgainst / 1000}K AIBC {voteAgainstPercent.toFixed(2)}%</div>
                                        </div>
                                        <div className='vote-item-progress'>
                                            <div className="progress-placeholder"></div>
                                            <div className='progress-full' style={{ width: `${voteAgainstPercent}%`}}></div>
                                            <div className='progress-empty' style={{ width: `${voteForPercent}%`}}></div>
                                        </div>
                                    </div>
                                    <div className="vote-item">
                                        <div className="vote-item-title">
                                            <div className='title-left'>Quorum</div>
                                            <div className='title-right'>{totalVotes / 1000}K / 150M</div>
                                        </div>
                                        <div className='vote-item-progress'>
                                            <div className="progress-placeholder"></div>
                                            <div className='progress-full' style={{ width: `${quorumPercent > 100 ? 100 : quorumPercent}%`}}></div>
                                            <div className='progress-empty' style={{ width: `${restQuorumPercent < 0 ? 0 : restQuorumPercent}%`}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-proposal-voting-period mb-4">
                        <label>Staking Information</label>
                        <div className='create-proposal-period-selection'>
                            <div className='selection-item'>
                                <label>Your Staked Amount: {stakedBalance} AIBC, Your AIBC Balance: {aibcBalance} AIBC</label>
                                <div className='create-proposal-stake'>
                                    <input
                                        type="text"
                                        className='create-proposal-stake-input'
                                        value={amount}
                                        onChange={(e) => setAmount(parseInt(e.target.value == ""? '0': e.target.value))}
                                    />
                                    {amount > stakedAllowance && <button className='create-proposal-submit-button approve' onClick={onApprove}>Approve</button>}
                                    <button className='create-proposal-submit-button' onClick={onStake}>Stake</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {currentProposal && Number(currentProposal.status) == 1  && 
                      <div>
                        <label>Vote</label>
                        {currentProposal && currentProposal.didVote ? <div>You've already voted</div>:
                         <div className='proposal-vote-buttons d-flex gap-5'>
                            <button className="create-proposal-submit-button" onClick={(e) => onVote(true)}>Agree</button>
                            <button className="create-proposal-submit-button" onClick={(e) => onVote(false)}>Disagree</button>
                        </div>
                        }
                     </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default VoteMembershipProposal;
