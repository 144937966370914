export const AIBC_ADDRESS = '0xd088a3b39BF465EA61541bb7226df2737b25Ef85'
export const USDC_ADDRESS = '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'
export const AIBC_DONATION_ADDRESS = '0x284Fc4dD8e225e0d5D2a468d69eB2035416460Fc'
export const AIBC_STAKING_ADDRESS = '0xf52836C307D2964D5983A6fe6e47505800248e10'
export const AIBC_DAO_ADDRESS = '0xfD89cbB39194DaE5571397970D8937d84fB68a36'
export const AIBC_USDC_PAIR_ADDRESS = '0x4459C4603B3aDeebb56580A036F8BFd351A9e6D2'
export const TEST_USDC_ADDRESS = '0x7fB8B0F55d520f7FDAF558a126E9A9a536CF1865'
export const AIBC_STAKING_REWARDS_30_ADDRESS = '0x026DbDE741b4aFB6dAc15f6b3c00BD6C72B91CFF'
export const AIBC_STAKING_REWARDS_60_ADDRESS = '0x9188a688ff2d5ec8BCDeE46f4a6Bd09D98cFFF90'
export const AIBC_STAKING_REWARDS_90_ADDRESS = '0xcFce338A868375706e4EbE9E382E2C4Bd8660c44'
export const AIBC_STAKING_REWARDS_180_ADDRESS = '0xEd40e4a7Fcb9c2019E85eb9bAd6944331190eDb0'
export const PINATA_API_KEY = '3d148d40a6d1b2762d5f'
export const PINATA_API_SECRET_KEY = 'd6f1f084858f35c0c4d5161e266da3b489f63b53bdbe770a399546459e4f8cc0'
export const POLYGON_BLOCK_MINING_SPEED = 4
export const POLYGON_RPC_URL = 'https://polygon-mainnet.g.alchemy.com/v2/eMY6MFJNWoq7P3I5EnZTmqHqOPSphnAq'

export const STAKING_STATUS = {
    STAKING: 0,
    ORDER_ID: 1,
    INVOICE_ID: 2,
    SKU_NUMBER: 3,
    RETURNED: 4
}